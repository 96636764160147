export const fetchBusinessAvailableState = (state, data) => {
    state.businessStates = data
}

export const fetchBusinessById = (state, data) => {
    state.business = data
}

export const fetchBusinessByState = (state, data) => {
    state.businesses = data
}

export const createBusiness = (state, data) => {
    state.auth.clusters = [data, ...state.auth.clusters ]
    state.business = data
}

export const joinBusiness = (state, data) => {
    const cluster = data && data.business ? data.business : data
    const clusters = state.auth.clusters || []
    const existing = clusters.find( c => c.id === cluster.id)
    if (!existing) {
        state.auth.clusters = [
            cluster,
            ...clusters
        ]
    }
}


const BusinessMutation = {
    fetchBusinessAvailableState,
    fetchBusinessByState,
    fetchBusinessById,
    joinBusiness,
    createBusiness
};

export default BusinessMutation;