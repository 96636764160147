<template>
  <div class="container">
    <div class="colorful-tab colorful-tab-with-btn" v-if="ACCOUNT_TYPES.HOME === activeAccount">
      <ul class="nav p-1 mb-3 relative shadow-sm" id="affanTab3" role="tablist">
        <li class="nav-item" role="presentation" id="step_1">
          <button class="btn btn-sm btn-primary" 
            :class="activeAccount === ACCOUNT_TYPES.HOME ? 'active' : ''"
            id="home" 
            data-bs-toggle="tab" 
            @click="handleNavChange('home')"
            data-bs-target="#fiat" 
            type="button" 
            role="tab" 
            aria-controls="fiat" 
            aria-selected="true">
            <i class="pi pi-globe" style="font-size: 12px;"></i> Home
          </button>
        </li>
        <li class="nav-item" role="presentation" id="step_2">
          <product-package product="savings">
            <button 
              class="btn btn-sm btn-primary" 
              :class="activeAccount === ACCOUNT_TYPES.SAVINGS ? 'active' : ''"
              id="savings-tab" 
              @click="handleNavChange('savings')"
              data-bs-toggle="tab" 
              data-bs-target="#savings" 
              type="button" role="tab" 
              aria-controls="savings" 
              aria-selected="false">
              <i class="bi bi-piggy-bank" style="font-size: 14px;"></i> Savings
            </button>
          </product-package>
        </li>
        <li class="nav-item" role="presentation" id="step_3">
          <product-package product="loan">
            <button 
              class="btn btn-sm btn-primary" 
              :class="activeAccount === ACCOUNT_TYPES.LOAN ? 'active' : ''"
              id="loan-tab" 
              @click="handleNavChange('loan')"
              data-bs-toggle="tab" 
              data-bs-target="#loan" 
              type="button" 
              role="tab" 
              aria-controls="loan" 
              aria-selected="false">
              <i class="pi pi-building-columns" style="font-size: 12px;"></i> Loans
            </button>
          </product-package>
        </li>
        <li class="nav-item" role="presentation" id="step_4">
          <product-package product="investment">
            <button 
              class="btn btn-sm btn-primary" 
              :class="activeAccount === ACCOUNT_TYPES.INVESTMENT ? 'active' : ''"
              id="investment-tab" 
              @click="handleNavChange('investment')"
              data-bs-toggle="tab" 
              data-bs-target="#investment" 
              type="button" 
              role="tab" 
              aria-controls="investment" 
              aria-selected="true">
              <i class="pi pi-wave-pulse" style="font-size: 12px;"></i> Invest
            </button>
          </product-package>
        </li>
        <!-- <li class="nav-item" role="presentation" id="step_4" @click="setActiveFooterNav('cards')">
          <product-package product="card">
            <router-link 
              class="btn btn-sm btn-primary"
              id="card-tab"
              type="button" 
              role="tab"
              aria-controls="card" 
              aria-selected="true"
              to="/cards"
            >
              <i class="pi pi-credit-card" style="font-size: 12px;"></i> Cards
            </router-link>
          </product-package>
        </li> -->
      </ul>
    </div>
    <div v-else class="price-table-one mb-2">
      <ul class="nav nav-tabs border-bottom-0 mb-3 align-items-center justify-content-center mx-4">
        <span class="nav-item"></span>
        <span class="nav-item"></span>
        <li class="nav-item" role="presentation">
          <a class="nav-link shadow small" data-bs-toggle="tab" href="#" @click="handleNavChange('home')" role="tab" aria-controls="priceTab_Two" aria-selected="false">
            <i class="pi pi-home"></i>
          </a>
        </li>
        <li class="nav-item" role="presentation">home</li>
      </ul>
    </div>

    <div class="card bg-card-primary bg-gradient rounded-lg">
      <div class="card-body">
        <div class="colorful-tab colorful-tab-card" >
          <div class="tab-content shadow-sm p-3" id="affanTab3Content">
            <div class="tab-pane fade text-left" :class="activeAccount === ACCOUNT_TYPES.HOME ? 'show active' : ''" id="fiat" role="tabpanel" aria-labelledby="fiat-tab">
              <div class="d-flex justify-content-between">
                <h6 class="text-white align-items-center">
                  <div class="text-xs text-warning"><i class="bi bi-credit-card-2-back"></i> Balance</div>
                  <span v-if="visibility() && visibility() === 'no'" class="blur-white">XXXXXXXXXXX</span>
                  <span v-else>
                    {{ formatter(selectedFiatCurrency.symbol).format((selectedFiatCurrency.avialable || 0) + (planBalances[selectedFiatCurrency.symbol] || 0)) }} 
                  </span>
                </h6>
                <div class="align-items-end"  id="step_4">
                  <!-- <span class="text-xs text-warning">change wallet</span> -->
                  <div class="dropdown">
                    <button class="btn btn-primary dropdown-toggle bg-success" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {{ selectedFiatCurrency.symbol }}
                    </button>
                    <ul class="dropdown-menu bg-green-700">
                      <li v-for="(cur, i) in supportedFiatCurrencies" :key="i" @click="selectFiatCurrency(cur)">
                        <a class="dropdown-item" hid="#">{{ cur }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <div>
                  <p class="mb-0 badge rounded bg-success text-xm">
                    <small>
                      <i class="pi pi-sparkles"></i> Total Balance 
                      <span v-if="visibility() && visibility() === 'no'" class="blur-white">XXXXXXXXXXX</span>
                      <span v-else>
                        {{ formatter(selectedFiatCurrency.symbol).format(selectedFiatCurrency.balance) }} 
                      </span>
                    </small> 
                  </p>
                </div>
              </div>
              
              <div class="d-flex mt-2">
                <button class="btn btn-sm btn-warning shadow" data-bs-toggle="modal" @click="stage=null" data-bs-target="#payment-method" id="step_6">
                  <i class="pi pi-plus" style="font-size: 11px;"></i> Add Money
                </button>&nbsp;
                <button v-if="!is('restricted')" class="btn btn-sm btn-warning shadow" data-bs-toggle="modal" data-bs-target="#withdrawal-modal" id="step_7">
                  <i class="pi pi-sign-out" style="font-size: 11px;"></i> Send Money
                </button>
              </div>
                
            </div>
            <div class="tab-pane fade" :class="activeAccount === ACCOUNT_TYPES.SAVINGS ? 'show active' : ''" id="savings" role="tabpanel" aria-labelledby="savings-tab">
              <div class="d-flex justify-content-between">
                <h6 class="text-white flex">
                  <div class="text-xs text-warning"><i class="pi pi-wallet"></i> Savings</div>
                  <span v-if="visibility() && visibility() === 'no'" class="blur-white">XXXXXXXXXXX</span>
                  <span v-else> 
                    <!-- {{ formatter('USD').format((summary?.savings?.settled / rates()[baseCurrency]?.sell_rate) || 0) }} |  -->
                    {{ formatter(baseCurrency).format(summary?.savings?.settled || 0) }} 
                  </span>
                </h6>
                <div class="align-items-end wallet-icon">
                  <svg width="40px" height="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M4,5A1,1,0,0,0,5,6H21a1,1,0,0,1,1,1V21a1,1,0,0,1-1,1H16a1,1,0,0,1,0-2h4V8H5a2.966,2.966,0,0,1-1-.184V19a1,1,0,0,0,1,1h5a1,1,0,0,0,1-1V14.414L9.707,15.707a1,1,0,0,1-1.414-1.414l3-3a.99.99,0,0,1,.326-.217,1,1,0,0,1,.764,0,.99.99,0,0,1,.326.217l3,3a1,1,0,0,1-1.414,1.414L13,14.414V19a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2H21a1,1,0,0,1,0,2H5A1,1,0,0,0,4,5Z"/></svg>
                </div>
              </div>
              <div class="d-flex">
                <div>
                  <p class="mb-0 badge bg-success text-xm">
                    <small class=""><i class="pi pi-sparkles"></i> Target: 
                      <span v-if="visibility() && visibility() === 'no'" class="blur-white">XXXXXXXXXXX</span>
                      <span v-else>
                        <!-- {{ formatter('USD').format((summary?.savings?.balance / rates()[baseCurrency]?.sell_rate) || 0) }} |  -->
                        {{ formatter(baseCurrency).format((summary?.savings?.balance) || 0) }} 
                      </span>
                    </small> 
                  </p>
                </div>
              </div>

              <div class="d-flex mt-2">
                <router-link class="btn btn-sm btn-warning shadow" to="/saves">
                  <i class="pi pi-angle-double-right" style="font-size: 11px;"></i> Save Now
                </router-link>&nbsp;
                <button 
                  class="btn btn-sm btn-warning shadow" 
                  data-bs-toggle="modal" 
                  data-bs-target="#join-plan" 
                >
                  <i class="pi pi-users" style="font-size: 11px;"></i> Join Plan
                </button>
              </div>
            </div>
            <div class="tab-pane fade" :class="activeAccount === ACCOUNT_TYPES.LOAN ? 'show active' : ''" id="loan" role="tabpanel" aria-labelledby="loan-tab">
              <div class="d-flex justify-content-between">
                <h6 class="text-white flex">
                  <div class="text-xs text-warning"><i class="bi bi-credit-card"></i> Debt</div>
                  <span v-if="Object.values(summary).length > 0">
                    <span v-if="visibility() && visibility() === 'no'" class="blur-white">XXXXXXXXXXX</span>
                    <span v-else>
                      <!-- {{ formatter('USD').format(summary?.loan?.balance / (rates()[baseCurrency]?.sell_rate || 0)) }} |  -->
                      {{ formatter(baseCurrency).format(summary?.loan?.balance) }}
                    </span>  
                  </span>
                  <div v-else class="spinner-border spinner-border-sm text-warning" role="status">
                    <span class="sr-only"></span>
                  </div>
                </h6>
                <div class="align-items-end wallet-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40px" height="40px" viewBox="0 0 512 512" id="Layer_1" version="1.1" xml:space="preserve">
                  <g>
                    <path class="st0" d="M86.5,152c-15.7,0-28.5,12.8-28.5,28.5s12.8,28.5,28.5,28.5c15.7,0,28.5-12.8,28.5-28.5S102.2,152,86.5,152z    M86.5,194.9c-8,0-14.5-6.5-14.5-14.5S78.5,166,86.5,166c8,0,14.5,6.5,14.5,14.5S94.5,194.9,86.5,194.9z"/>
                    <path class="st0" d="M240.5,152c-15.7,0-28.5,12.8-28.5,28.5s12.8,28.5,28.5,28.5c15.7,0,28.5-12.8,28.5-28.5S256.2,152,240.5,152z    M240.5,194.9c-8,0-14.5-6.5-14.5-14.5s6.5-14.5,14.5-14.5c8,0,14.5,6.5,14.5,14.5S248.5,194.9,240.5,194.9z"/>
                    <path class="st0" d="M394.5,79.6c-15.7,0-28.5,12.8-28.5,28.5c0,15.7,12.8,28.5,28.5,28.5c15.7,0,28.5-12.8,28.5-28.5   C423,92.3,410.2,79.6,394.5,79.6z M394.5,122.5c-8,0-14.5-6.5-14.5-14.5c0-8,6.5-14.5,14.5-14.5c8,0,14.5,6.5,14.5,14.5   C409,116,402.5,122.5,394.5,122.5z"/>
                    <path class="st0" d="M483.3,164.5l-36.1-32.2c3.5-7.6,5.3-15.9,5.3-24.3c0-32-26-58-58-58s-58,26-58,58c0,10.5,2.8,20.3,7.7,28.7   l-47,31.5c-5.6-26.1-28.9-45.8-56.7-45.8c-29.6,0-54.1,22.3-57.6,51h-38.9c-3.5-28.7-28-51-57.6-51c-32,0-58,26-58,58s26,58,58,58   c29.6,0,54.1-22.3,57.6-51h38.9c3.5,28.7,28,51,57.6,51c30.7,0,55.9-24,57.9-54.2l54.2-36.3c10.6,11.1,25.5,18,42,18   c8.4,0,16.8-1.8,24.3-5.3l32.2,36.1c4.2,4.7,10.2,7.5,16.4,7.7c0.2,0,0.4,0,0.7,0c6,0,11.9-2.4,16.2-6.7c4.4-4.4,6.9-10.6,6.7-16.8   C490.8,174.7,488,168.7,483.3,164.5z M86.5,224.5c-24.3,0-44-19.7-44-44s19.7-44,44-44s44,19.7,44,44S110.8,224.5,86.5,224.5z    M240.5,224.5c-24.3,0-44-19.7-44-44s19.7-44,44-44c24.3,0,44,19.7,44,44c0,0,0,0,0,0v0C284.5,204.7,264.8,224.5,240.5,224.5z    M474.4,187.9C474.4,187.9,474.4,187.9,474.4,187.9c-1.8,1.8-4.1,2.7-6.5,2.6c-2.5-0.1-4.7-1.1-6.4-3l-35.8-40.2   c-1.4-1.5-3.3-2.3-5.2-2.3c-1.2,0-2.5,0.3-3.6,1c-6.7,4-14.5,6.1-22.4,6.1c-24.3,0-44-19.7-44-44s19.7-44,44-44s44,19.7,44,44   c0,7.9-2.1,15.6-6.1,22.4c-1.7,2.9-1.1,6.6,1.4,8.8L474,175c1.8,1.6,2.9,3.9,3,6.4C477.1,183.8,476.1,186.1,474.4,187.9z"/>
                    <path class="st0" d="M52,462h69h85h69h85h69h24c3.9,0,7-3.1,7-7s-3.1-7-7-7h-17V203c0-3.9-3.1-7-7-7h-69c-3.9,0-7,3.1-7,7v245   h-23.4v-75c0-3.9-3.1-7-7-7s-7,3.1-7,7v75H282V280c0-3.9-3.1-7-7-7h-69c-3.9,0-7,3.1-7,7v168h-28.5v-75c0-3.9-3.1-7-7-7s-7,3.1-7,7   v75H128V280c0-3.9-3.1-7-7-7H52c-3.9,0-7,3.1-7,7v168H28c-3.9,0-7,3.1-7,7s3.1,7,7,7H52z M367,210h55v238h-55V210z M213,287h55v161   h-55V287z M59,287h55v161H59V287z"/>
                  </g>
                </svg>
                </div>
              </div>
              <div class="d-flex">
                <div>
                  <p class="mb-0 badge bg-success text-xm">
                    <small class="">
                      <i class="pi pi-sparkles"></i> Paid: 
                      <span v-if="Object.values(summary).length > 0">
                        <span v-if="visibility() && visibility() === 'no'" class="blur-white">XXXXXXXXXXX</span>
                        <span v-else>
                          <!-- {{ formatter('USD').format(summary?.loan?.settled / (rates()[baseCurrency]?.sell_rate || 0)) }} |  -->
                          {{ formatter(baseCurrency).format(summary?.loan?.settled) }}
                        </span>
                      </span>
                      <div v-else class="spinner-border spinner-border-sm text-warning" role="status">
                        <span class="sr-only"></span>
                      </div>
                    </small> 
                  </p>
                </div>
              </div>

              <div class="d-flex mt-2">
                <router-link class="btn btn-sm btn-warning shadow" v-if="Object.keys(summary).length > 0 && !is('restricted')" to="/loans">
                  <i class="pi pi-angle-double-right" style="font-size: 11px;"></i> Request Loan
                </router-link>&nbsp;
                <button 
                  class="btn btn-sm btn-warning shadow" 
                  data-bs-toggle="modal" 
                  data-bs-target="#cluster-chat-room" 
                  v-if="Object.values(cluster).length > 0"
                >
                <i class="pi pi-users" style="font-size: 11px;"></i> Cluster
                </button>
                <button 
                  class="btn py-1 btn-sm btn-warning shadow" 
                  data-bs-toggle="modal" 
                  data-bs-target="#join-cluster" 
                  v-else
                >
                <i class="pi pi-users" style="font-size: 11px;"></i> Join Cluster
                </button>
              </div>
            </div>
            <div class="tab-pane fade" :class="activeAccount === ACCOUNT_TYPES.INVESTMENT ? 'show active' : ''" id="investment" role="tabpanel" aria-labelledby="investment-tab">
              <h6 class="text-white py-2 px-2 flex bg-success rounded"><i class="pi pi-wallet"></i> Balance: 
                <span v-if="visibility() && visibility() === 'no'" class="blur-white">XXXXXXXXXXX</span>
                <span v-else>
                  <!-- {{ formatter('USD').format(totalWalletBalanceUSD) }} |  -->
                  {{ formatter(baseCurrency).format(totalWalletBalance) }} 
                </span>  
              </h6>
              <div class="d-flex">
                <table class="table mb-0 table-hover text-white" >
                  <thead>
                    <th>
                        Currency
                    </th>
                    <th>
                        Amount
                    </th>
                  </thead>
                  <tbody>
                    <template v-if="wallet">
                      <tr v-for="(cur, i) in supportedFiatCurrencies" :key="i" @click="navigate('fiat-tab', supportedFiatCurrencies[i])" style="cursor:pointer">
                        <td>{{ cur }}</td>
                        <td>
                          <span v-if="visibility() && visibility() === 'no'" class="blur-white">XXXXXXXXXXX</span>
                          <span v-else>{{ formatter(cur).format((wallet[cur] ? wallet[cur].balance : 0 ) + (planBalances[cur] || 0 ))}}</span>
                        </td>
                      </tr>
                    </template>
                    <template v-if="wallet && activeAccount === ACCOUNT_TYPES.INVESTMENT">
                      <tr v-for="(cur, i) in supportedCryptoCurrencies" :key="i" @click="navigate('crypto-tab', supportedCryptoCurrencies[i])" style="cursor:pointer">
                        <td>{{ cur }}</td>
                        <td>
                          <span v-if="visibility() && visibility() === 'no'" class="blur-white">XXXXXXXXXXX</span>
                          <span v-else>{{ Math.round(((wallet[cur] ? wallet[cur].balance : 0 ) + (planBalances[cur] || 0 )) * 100) / 100}}</span>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>

              <div class="d-flex mt-4">
                <router-link class="btn m-1 btn-sm btn-warning shadow" to="/plans">
                  <i class="bi bi-emoji-sunglasses" style="font-size: 11px;"></i> Start Investment
                </router-link>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container px-0" id="step_8" v-if="activeAccount === ACCOUNT_TYPES.HOME">
      <ul class="box-service mt-3">
        <li>
          <router-link class="stretched-link" :to="{ path: 'services', query: { type: 'airtime' }}">
            <div class="icon-box bg_color_1">
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_4355_17017)">
                  <rect x="6.375" y="2" width="12" height="19" fill="white"></rect>
                  <path d="M17.7247 0H7.02484C6.13341 0 5.40381 0.728914 5.40381 1.62103V22.3783C5.40381 23.2704 6.13341 24 7.02484 24H17.7247C18.6161 24 19.3457 23.2718 19.3457 22.379V1.62103C19.3457 0.728914 18.6161 0 17.7247 0ZM10.6632 1.16846H14.0863C14.1947 1.16846 14.2824 1.25623 14.2824 1.36526C14.2824 1.4736 14.1947 1.56137 14.0863 1.56137H10.6632C10.5549 1.56137 10.4671 1.4736 10.4671 1.36526C10.4671 1.25623 10.5549 1.16846 10.6632 1.16846ZM12.3748 23.1895C11.927 23.1895 11.5643 22.8267 11.5643 22.3783C11.5643 21.9298 11.927 21.5678 12.3748 21.5678C12.8226 21.5678 13.1853 21.9298 13.1853 22.3783C13.1853 22.8267 12.8226 23.1895 12.3748 23.1895ZM18.2177 21H6.53181V2.57074H18.2177V21Z" fill="#283EB4"></path>
                  <path d="M12.3749 6C13.9704 6 15.4719 6.6175 16.6019 7.7385C16.7979 7.9325 16.7994 8.2495 16.6049 8.445C16.5074 8.5435 16.3784 8.593 16.2499 8.593C16.1229 8.593 15.9954 8.5445 15.8979 8.448C14.9564 7.5145 13.7049 7 12.3749 7C11.0449 7 9.79344 7.5145 8.85194 8.4485C8.65644 8.643 8.33944 8.642 8.14494 8.4455C7.95044 8.2495 7.95194 7.9325 8.14794 7.7385C9.27794 6.6175 10.7794 6 12.3749 6Z" fill="#39A3F8"></path>
                  <path d="M11.25 12.75H10C9.793 12.75 9.625 12.918 9.625 13.125C9.625 13.332 9.793 13.5 10 13.5H11.25C11.319 13.5 11.375 13.556 11.375 13.625V14.375H10C9.793 14.375 9.625 14.543 9.625 14.75C9.625 14.957 9.793 15.125 10 15.125H11.375V15.875C11.375 15.944 11.319 16 11.25 16H10C9.793 16 9.625 16.168 9.625 16.375C9.625 16.582 9.793 16.75 10 16.75H11.25C11.7325 16.75 12.125 16.3575 12.125 15.875V13.625C12.125 13.1425 11.7325 12.75 11.25 12.75Z" fill="#39A3F8"></path>
                  <path d="M14.75 14.5H14C13.793 14.5 13.625 14.668 13.625 14.875C13.625 15.082 13.793 15.25 14 15.25H14.375V16H13.5C13.431 16 13.375 15.944 13.375 15.875V13.625C13.375 13.556 13.431 13.5 13.5 13.5H14.75C14.957 13.5 15.125 13.332 15.125 13.125C15.125 12.918 14.957 12.75 14.75 12.75H13.5C13.0175 12.75 12.625 13.1425 12.625 13.625V15.875C12.625 16.3575 13.0175 16.75 13.5 16.75H14.75C14.957 16.75 15.125 16.582 15.125 16.375V14.875C15.125 14.668 14.957 14.5 14.75 14.5Z" fill="#39A3F8"></path>
                  <path d="M12.3751 7.89099C11.2896 7.89099 10.2671 8.31199 9.49706 9.07649C9.30106 9.27099 9.29956 9.58699 9.49406 9.78299C9.68906 9.97949 10.0051 9.98049 10.2011 9.78599C10.7831 9.20849 11.5551 8.89099 12.3751 8.89099C13.1951 8.89099 13.9671 9.20899 14.5491 9.78649C14.6466 9.88299 14.7736 9.93149 14.9011 9.93149C15.0296 9.93149 15.1586 9.88199 15.2561 9.78349C15.4506 9.58749 15.4491 9.27099 15.2531 9.07649C14.4831 8.31199 13.4606 7.89099 12.3751 7.89099Z" fill="#39A3F8"></path>
                  <path d="M10.8429 10.4165C11.2489 10.0075 11.7929 9.78198 12.3749 9.78198C12.9569 9.78198 13.5009 10.0075 13.9069 10.416C14.1014 10.612 14.1004 10.9285 13.9044 11.123C13.8064 11.22 13.6794 11.2685 13.5519 11.2685C13.4234 11.2685 13.2949 11.2195 13.1974 11.121C12.7629 10.6835 11.9869 10.6835 11.5524 11.121C11.3579 11.3165 11.0414 11.318 10.8454 11.1235C10.6494 10.929 10.6484 10.6125 10.8429 10.4165Z" fill="#39A3F8"></path>
                  </g>
                  <defs>
                  <clipPath id="clip0_4355_17017">
                  <rect width="24" height="24" fill="white" transform="translate(0.375)"></rect>
                  </clipPath>
                  </defs>
              </svg>
            </div>
            <span>Airtime</span>
          </router-link>
        </li>
        <li>
          <router-link class="stretched-link" :to="{ path: 'services', query: { type: 'data' }}">
            <div class="icon-box bg_color_1">
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4355_17182)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1251 3.44482C11.8537 3.44482 11.5823 3.44929 11.3754 3.45819C7.2762 3.6346 3.36945 5.25275 0.34614 8.02658C0.0409477 8.30659 0.0419856 8.78279 0.334879 9.07568L1.73847 10.4793C2.03137 10.7722 2.50495 10.7704 2.81312 10.4937C5.17879 8.36971 8.20079 7.11796 11.3755 6.94708C11.7891 6.92482 12.4612 6.92482 12.8748 6.94708C16.0495 7.11796 19.0716 8.36971 21.4372 10.4937C21.7454 10.7704 22.219 10.7722 22.5119 10.4793L23.9154 9.07568C24.2083 8.78279 24.2094 8.30659 23.9042 8.02658C20.8809 5.25275 16.9742 3.63459 12.8749 3.45819C12.668 3.44929 12.3966 3.44482 12.1251 3.44482ZM12.0831 10.4116L11.3339 10.4416C9.09872 10.6112 6.9762 11.4953 5.28166 12.9627C4.9686 13.2338 4.97027 13.7111 5.26316 14.004L6.66667 15.4076C6.95956 15.7005 7.43228 15.6967 7.75477 15.437C8.79066 14.603 10.0444 14.0826 11.3663 13.9378L12.1251 13.8964C14.1994 14.0799 15.4569 14.6008 16.4955 15.437C16.818 15.6967 17.2907 15.7005 17.5836 15.4076L18.9872 14.004C19.2801 13.7111 19.2817 13.2338 18.9686 12.9627C17.2632 11.4859 15.1247 10.6002 12.8745 10.4385C12.4614 10.4089 12.1063 10.4116 12.0831 10.4116ZM12.1251 17.3954C11.854 17.3954 11.5828 17.4175 11.3809 17.4616C10.9771 17.5499 10.5921 17.7094 10.2442 17.9325C9.89616 18.1556 9.89855 18.6394 10.1914 18.9323L11.5949 20.3357C11.7355 20.4763 11.9263 20.5553 12.1252 20.5553C12.3241 20.5553 12.5148 20.4763 12.6555 20.3357L14.0589 18.9323C14.3518 18.6394 14.3542 18.1556 14.0062 17.9325C13.6582 17.7094 13.2732 17.5499 12.8694 17.4616C12.6674 17.4175 12.3963 17.3954 12.1251 17.3954Z" fill="#1E90FF"></path>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.134 3.44482V6.93042C12.4023 6.93056 12.6702 6.93599 12.8746 6.94699C16.0493 7.11786 19.0714 8.36962 21.4371 10.4937C21.7453 10.7703 22.2189 10.7721 22.5118 10.4792L23.9153 9.07559C24.2082 8.7827 24.2093 8.3065 23.9041 8.02649C20.8808 5.25266 16.974 3.6345 12.8748 3.4581C12.6703 3.4493 12.4024 3.44492 12.134 3.44482ZM0.133981 8.39334C0.110813 8.49681 0.111064 8.60452 0.133981 8.70874V8.39334ZM12.134 10.4115V13.8971C14.2028 14.0814 15.4582 14.602 16.4954 15.437C16.8179 15.6966 17.2906 15.7004 17.5835 15.4075L18.9871 14.0039C19.28 13.711 19.2815 13.2337 18.9685 12.9626C17.2631 11.4858 15.1246 10.6001 12.8744 10.4384C12.5312 10.4138 12.2401 10.4116 12.134 10.4115ZM12.134 17.3955V20.5552C12.3298 20.5529 12.5169 20.474 12.6554 20.3356L14.0588 18.9322C14.3517 18.6393 14.3541 18.1556 14.0061 17.9324C13.6581 17.7093 13.273 17.5498 12.8692 17.4615C12.6695 17.4179 12.4021 17.396 12.134 17.3955Z" fill="#0584FF"></path>
                </g>
                <defs>
                <clipPath id="clip0_4355_17182">
                <rect width="24" height="24" fill="white" transform="translate(0.125)"></rect>
                </clipPath>
                </defs>
              </svg>
            </div>
            <span>Data</span>
          </router-link>
        </li>
        <li>
          <router-link class="stretched-link" :to="{ path: 'services', query: { type: 'cable' }}">
            <div class="icon-box bg_color_1">
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.87511 1.25C5.49974 1.24954 5.1266 1.30757 4.76911 1.422C4.00043 1.65173 3.32708 2.12475 2.85029 2.76998C2.37349 3.4152 2.11901 4.19775 2.12511 5V10C2.12564 10.7292 2.41554 11.4284 2.93115 11.944C3.44676 12.4596 4.14592 12.7495 4.87511 12.75H8.87511C9.07402 12.75 9.26479 12.671 9.40544 12.5303C9.54609 12.3897 9.62511 12.1989 9.62511 12V5C9.62379 4.00585 9.22827 3.05279 8.5253 2.34981C7.82232 1.64684 6.86926 1.25133 5.87511 1.25Z" fill="#DA9B00"></path>
                <path d="M23.6248 6.00002V20C23.625 20.3612 23.5541 20.7189 23.416 21.0527C23.2779 21.3865 23.0753 21.6897 22.8199 21.9452C22.5645 22.2006 22.2612 22.4031 21.9275 22.5412C21.5937 22.6793 21.236 22.7503 20.8748 22.75H10.8748C10.5136 22.7503 10.1558 22.6793 9.82208 22.5412C9.48831 22.4031 9.18505 22.2006 8.92964 21.9452C8.67422 21.6897 8.47167 21.3865 8.33356 21.0527C8.19545 20.7189 8.1245 20.3612 8.12477 20V4.25002C8.12328 3.54389 7.87275 2.86092 7.41729 2.32131C6.96182 1.78171 6.33062 1.42006 5.63477 1.30002C5.70929 1.26345 5.79184 1.24626 5.87477 1.25002H18.8748C20.1342 1.25108 21.3418 1.75186 22.2324 2.64243C23.1229 3.533 23.6237 4.74057 23.6248 6.00002Z" fill="#FECC0E"></path>
                <path d="M15.875 8.75H12.875C12.6761 8.75 12.4853 8.67098 12.3447 8.53033C12.204 8.38968 12.125 8.19891 12.125 8C12.125 7.80109 12.204 7.61032 12.3447 7.46967C12.4853 7.32902 12.6761 7.25 12.875 7.25H15.875C16.0739 7.25 16.2647 7.32902 16.4053 7.46967C16.546 7.61032 16.625 7.80109 16.625 8C16.625 8.19891 16.546 8.38968 16.4053 8.53033C16.2647 8.67098 16.0739 8.75 15.875 8.75Z" fill="white"></path>
                <path d="M18.875 11.75H12.875C12.6761 11.75 12.4853 11.671 12.3447 11.5303C12.204 11.3897 12.125 11.1989 12.125 11C12.125 10.8011 12.204 10.6103 12.3447 10.4697C12.4853 10.329 12.6761 10.25 12.875 10.25H18.875C19.0739 10.25 19.2647 10.329 19.4053 10.4697C19.546 10.6103 19.625 10.8011 19.625 11C19.625 11.1989 19.546 11.3897 19.4053 11.5303C19.2647 11.671 19.0739 11.75 18.875 11.75Z" fill="white"></path>
                <path d="M18.875 14.75H12.875C12.6761 14.75 12.4853 14.671 12.3447 14.5303C12.204 14.3897 12.125 14.1989 12.125 14C12.125 13.8011 12.204 13.6103 12.3447 13.4697C12.4853 13.329 12.6761 13.25 12.875 13.25H18.875C19.0739 13.25 19.2647 13.329 19.4053 13.4697C19.546 13.6103 19.625 13.8011 19.625 14C19.625 14.1989 19.546 14.3897 19.4053 14.5303C19.2647 14.671 19.0739 14.75 18.875 14.75Z" fill="white"></path>
                <path d="M18.875 17.75H12.875C12.6761 17.75 12.4853 17.671 12.3447 17.5303C12.204 17.3897 12.125 17.1989 12.125 17C12.125 16.8011 12.204 16.6103 12.3447 16.4697C12.4853 16.329 12.6761 16.25 12.875 16.25H18.875C19.0739 16.25 19.2647 16.329 19.4053 16.4697C19.546 16.6103 19.625 16.8011 19.625 17C19.625 17.1989 19.546 17.3897 19.4053 17.5303C19.2647 17.671 19.0739 17.75 18.875 17.75Z" fill="white"></path>
              </svg>
            </div>
            <span>Cable</span>
          </router-link>
        </li>
        <li>
          <router-link class="stretched-link" :to="{ path: 'services', query: { type: 'power' }}">
            <div class="icon-box bg_color_1">
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4355_16088)">
                <path d="M20.3247 10.3362C20.1985 10.1213 19.9678 9.98934 19.7186 9.98934H14.5737L15.2515 0.754543C15.2756 0.425527 15.0678 0.12398 14.7518 0.0294335C14.4357 -0.0653009 13.7855 -0.288481 13.625 -0.000246391L6.91695 12.9651C6.79568 13.1828 6.79878 13.4485 6.92506 13.6634C7.05134 13.8783 7.28201 14.0102 7.53124 14.0102H12.6762L11.625 23.9998H12.625C12.9436 24.0952 13.1548 23.9244 13.3139 23.6386L20.3329 11.0346C20.4541 10.8168 20.451 10.5511 20.3247 10.3362Z" fill="#C8A52A"></path>
                <path d="M19.3247 10.3364C19.1985 10.1215 18.9678 9.98956 18.7186 9.98956H13.5737L14.2515 0.754767C14.2756 0.425751 14.0678 0.124204 13.7518 0.0296573C13.4357 -0.0650771 13.0965 0.0729229 12.936 0.361157L5.91695 12.9653C5.79568 13.1831 5.79878 13.4487 5.92506 13.6636C6.05134 13.8785 6.28201 14.0105 6.53124 14.0105H11.6762L10.9983 23.2453C10.9742 23.5743 11.182 23.8758 11.4981 23.9704C11.8167 24.0658 12.1548 23.9246 12.3139 23.6389L19.3329 11.0348C19.4541 10.817 19.451 10.5513 19.3247 10.3364Z" fill="#F2C31C"></path>
                </g>
                <defs>
                <clipPath id="clip0_4355_16088">
                <rect width="24" height="24" fill="white" transform="translate(0.625)"></rect>
                </clipPath>
                </defs>
              </svg>
            </div>
            <span>Electricity</span>
          </router-link>
        </li>
      </ul>
    </div>

    <modal title="Receive" id="cryptoReceiveModal">
      <div class="text-center"> 
        <h6>Status</h6>
      </div>
      <div class="text-center">
        feature will be available soon
      </div>
    </modal>

    <modal title="Send" id="cryptoSendModal">
      <div class="text-center"> 
        <!-- <h6>We will send to the address provided</h6> -->
        <h6>Status</h6>
      </div>
      <div class="text-center">
        this feature will be available soon
        <!-- <form>
          <div class="form-group">
            <label class="form-label" for="address">Address</label>
            <input class="form-control form-control-clicked" id="address" type="text" placeholder="1mbc....">
          </div>

          <div class="form-group">
            <label class="form-label" for="crypto-amount">Amount</label>
            <input class="form-control form-control-clicked" id="crypto-amount" type="number" placeholder="0.2">
          </div>

          <button class="btn m-1 btn-sm btn-warning shadow" @click="astroPayment">
            Send
          </button>
        </form> -->
      </div>
    </modal>

    <modal id="join-plan">
      <transition name="bounce">
        <div v-if="!stage">
          <h6>
              Plan Code
              <br>
              <small>Enter plan code</small>
          </h6>
          <div class="form-group">
            <label class="form-label" for="userNameInput">Code</label>
            <input class="form-control form-control-clicked" id="userNameInput" v-model="v$.subscriptionCode.$model" type="text" placeholder="333-AAA">
            <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.subscriptionCode.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div class="text-center my-1 mb-8">
              <app-button 
              @click="findPlan" 
              :title="'Preview'"
              :disabled="loaders().fetchByCode || v$.subscriptionCode.$invalid"
              :loading="loaders().fetchByCode"
              ></app-button>
          </div>
          <div class="input-errors text-xs text-danger mt-2" v-for="([key, error], index) of Object.entries(errors)" :key="index">
            <div class="error-msg">{{ key }}: {{ error }}</div>
          </div>
        </div>
      </transition>
      <transition name="bounce">
        <div v-if="stage === stages.PREVIEW_PLAN" class="single-plan-check shadow-sm active-effect d-block">
          <h5>{{ planUser.code }}</h5>
          <div class="w-full flex">
            {{ planUser.name }} <small> &nbsp; ({{ planUser.type }})</small>
            <br/>
            {{ planUser.base_currency }} {{ planUser.base_currency_amount }} in 
            {{ planUser.duration }} {{ planUser.duration_unit }}{{ parseInt(planUser.duration) > 0 ? 's' :'' }}
          </div>
          <div class="text-center my-1 mb-8">
              <app-button 
              @click="joinSubPlan" 
              :title="'Confirm'"
              :disabled="loaders().joinPlan || v$.subscriptionCode.$invalid"
              :loading="loaders().joinPlan"
              ></app-button>
          </div>
          <div class="input-errors text-xs text-danger mt-2" v-for="([key, error], index) of Object.entries(errors)" :key="index">
            <div class="error-msg">{{ key }}: {{ error }}</div>
          </div>
          <hr/>
          <div @click="stage=null">back</div>
        </div>
      </transition>
      <transition name="bounce">
        <success-notification 
          v-if="stage === stages.JOIN_PLAN_COMPLETED"
          title="Successful" 
          message="Thank You!" 
          nextPageName="Done"
          @close="() => stage=null"
        ></success-notification>
      </transition>  
    </modal>

    <modal-business id="join-cluster"></modal-business>
    <modal-chat id="cluster-chat-room" :cluster="cluster"></modal-chat>
    <modal-fund-account id="payment-method" :currency="selectedFiatCurrency"></modal-fund-account>

    <modal-withdrawal 
      id="withdrawal-modal"
      :source="funding_source"
    ></modal-withdrawal>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Modal from '@/components/common/modal/Modal.vue'
import ModalBusiness from '@/components/common/modal/ModalBusiness.vue'
import ModalFundAccount from '@/components/common/modal/ModalFundAccount.vue'
import ModalChat from '@/components/common/modal/ModalChat.vue'
import ProductPackage from '@/components/common/guard/ProductPackage.vue'
import "@/assets/js/astropay.js";
import { ASTRO_PAY_APP_ID, ENVIRONMENT } from '../../../utils/constant.util';
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import AppButton from '@/components/common/form/AppButton.vue';
import { createToaster } from "@meforma/vue-toaster";
import { printDiv, formatter } from '@/utils';
import moment from 'moment'
import { ACCOUNT_TYPES } from "../../../store/states/account.state"
// import { tns } from "tiny-slider";
import { defineComponent } from 'vue'
import SuccessNotification from '@/components/common/notification/SuccessNotification.vue'
import 'shepherd.js/dist/css/shepherd.css';
import Chat from '@/services/chat/chat';
import { StreamChatProvider } from '@/services/chat/providers';
import { bankAccount } from '@/mixins';
import ModalWithdrawal from '@/components/common/modal/ModalWithdrawal.vue'
const toaster = createToaster({ /* options */ });

export default defineComponent({
  name: 'Balance',
  mixins: [bankAccount],
  components: {
    Modal,
    AppButton,
    SuccessNotification,
    ModalBusiness,
    ModalChat,
    ProductPackage,
    ModalFundAccount,
    ModalWithdrawal
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      funding_source: { required },
      walletNumber: { required },
      withdraw: {
        confirm: { required },
        amount: { required, min: minLength(1) },
        description: {}
      },
      subscriptionCode: { required, min: minLength(6) },
      bankAccount: {
        account_number: { required },
        bank: { required },
        account_name: { required },
        save_beneficiary: {},
        description: {}
      }
    }
  },
  data() {
    return {
      useBeneficiary: false,
      isSuccessful: false,
      transferFee: 0,
      transferRate: 1,
      confirmReciept: false,
      funding_source: {},
      transferType: null,
      baseCurrency: "NGN",
      recepientFiatCurrency: "NGN",
      supportedFiatCurrencies: ["NGN"], // ["NGN", "USD", "GPB"],
      supportedCryptoCurrencies: [], // ["BTC", "ETH", "USDT"],
      selectedFiatCurrency: {
        symbol: this.baseCurrency,
        balance: 0,
        avialable: 0,
        identity: ""
      },
      selectedCryptoCurrency: {
        symbol: "USDT",
        balance: 0,
        avialable: 0,
        identity: ""
      },
      user: {},
      withdraw: {},
      bank: null,
      wallet: null,
      summary: {
        loan: {},
        savings: {}
      },
      walletNumber: null,
      planBalances: {},
      totalWalletBalance: 0,
      totalWalletBalanceUSD: 0,
      ACCOUNT_TYPES,
      tour: null,
      errors: {},
      subscriptionCode: "",
      stage: null,
      stages: {
        JOIN_PLAN_COMPLETED: "join_plan_completed",
        PREVIEW_PLAN: "preview_plan",
      },
      planUser: {},
      bankAccount: {},
      chat: null,
    }
  },
  created() {
    this.moment = moment;
    this.printDiv = printDiv;
    this.formatter = formatter
  },
  mounted() {

    const ASTROPAY_CONFIG = {
      environment: ENVIRONMENT
    };
    
    window.AstropaySDK.init(ASTRO_PAY_APP_ID, ASTROPAY_CONFIG);
    this.selectFiatCurrency(this.baseCurrency)
    // this.resetSlide()

    const toured = window.localStorage.getItem("toured")
    // console.log(toured)
    if (!toured || (toured && toured !== "done")) {
      this.createTour();
      this.tour.start();
    }
  },
  computed: {
    account () {
      const { summary = {}, wallet = {}, plans = {} } = this.$store.getters.account
      this.summary = summary;
      this.wallet = wallet;
      this.selectFiatCurrency(this.selectedFiatCurrency.symbol);
      this.selectCryptoCurrency(this.selectedCryptoCurrency.symbol);
      this.calcPlanBalances(plans);
      this.calcTotalWalletBalance();
    },
    cluster() {
      const { clusters = []} = this.auth()
      return  clusters[0] || {}
    },
    ...mapGetters([
      "activeAccount"
    ]),
  },
  watch: {
    account () {
      // console.log("ehn")
    },
    rates () {
      this.calcTotalWalletBalance()
    },
    funding_source(fs) {
      if (!this.bank) return
      const receiverCurrency = this.rates()[this.bank.bank_name];
      const senderCurrency = this.rates()[fs.base_currency];
      if ( ! receiverCurrency ) {
        // toaster.info( this.bank.bank_name + " not supported yet!" );
        return
      }
      if ( ! senderCurrency ) {
        toaster.info( fs.base_currency + " not supported yet!" );
        return
      }
      this.transferRate = receiverCurrency.sell_rate / senderCurrency.sell_rate
    },
    '$store.state.auth': async function (auth) {
      const { clusters = [] } = auth;
      const { uuid: businessUUID } = clusters[0] || {}

      if ( businessUUID && !this.chat ) {
        this.chat = new Chat(new StreamChatProvider());

        const user = await this.chat.user();
        this.chat.join({cid: businessUUID, userId: user.id}).then()
      }
    }
  },
  methods: {
    ...mapActions([
      "switchAccount",
      "logout",
      "fetchByCode",
      "joinPlan",
    ]),
    ...mapGetters([
      "loaders",
      "auth",
      "rates",
      "visibility",
    ]),
    ...mapMutations([
      "setActiveFooterNav"
    ]),
    joinSubPlan() {
      if ( !this.subscriptionCode ) {
        this.errors = {message: "You cannot subscribe a user to this plan"}
        return
      }
      const self = this
      this.joinPlan({user: this.auth().id, code: this.subscriptionCode}).then (r => {
        toaster.success("You have joined " + r.name + " plan. Kindly reload.")
        self.stage = self.stages.JOIN_PLAN_COMPLETED
      }).catch( e => {
        const { message } = e
        this.errors = { message }
      });
    },
    findPlan() {
      if ( !this.subscriptionCode ) {
        this.errors = {message: "You cannot subscribe a user to this plan"}
        return
      }
      this.fetchByCode(this.subscriptionCode).then (r => {
        toaster.success("Kindly confirm plan " + r.name + ".")
        this.stage = this.stages.PREVIEW_PLAN
        this.planUser = r;
      }).catch( e => {
        const { message } = e
        this.errors = { message }
      });
    },
    navigate(id, currency) {
      window.document.getElementById(id).click();
      if ( id === "crypto-tab") {
        this.selectCryptoCurrency(currency)
      } else {
        this.selectFiatCurrency(currency)
      }
    },

    astroPayment() {
      const ASTROPAY_EXTERNAL_DEPOSIT_ID = "JW-" + Math.random(9999999999999999);
      const resp = window.AstropaySDK.showDeposit(ASTROPAY_EXTERNAL_DEPOSIT_ID);
      console.log(resp);
    },

    selectFiatCurrency(cur) {
      // cur = cur || this.baseCurrency;
      const wallet = this.wallet && this.wallet[cur] ? this.wallet:  null
      this.funding_source = wallet ? wallet[cur]: {};
      this.selectedFiatCurrency = {
        identity:  wallet ? wallet[cur].identity: "--",
        symbol: wallet ? wallet[cur].base_currency: this.baseCurrency,
        avialable: wallet ? wallet[cur].ledger_balance: 0,
        balance: wallet ? wallet[cur].balance: 0
      };
      this.recepientFiatCurrency = this.selectedFiatCurrency.symbol
    },
    selectCryptoCurrency(cur) {
      const wallet = this.wallet && this.wallet[cur] ? this.wallet:  null
      this.selectedCryptoCurrency = {
        identity:  wallet ? wallet[cur].address: "--",
        symbol: cur,
        avialable: wallet ? wallet[cur].ledger_balance: 0,
        balance: wallet ? wallet[cur].balance: 0
      }     
    },
    calcPlanBalances(plans) {
      this.planBalances = {}
      for ( let i in plans ) {
        const { base_currency, subscription } = plans[i];
        if (!( base_currency in this.planBalances)) this.planBalances[base_currency] = 0;
        const { savings, status, amount } = subscription;
        // let coinSum = status === 'ACTIVE2' ? amount : 0;
        const savingSum = savings.reduce( (t, saving) => {
          return t + saving.status === "PAID" ? saving.amount_paid: 0;
        }, 0);
        
        this.planBalances[base_currency] += savingSum;
      }
    },
    calcTotalWalletBalance() {
      if ( !this.wallet || !this.rates() || Object.keys(this.planBalances).length === 0 ) {
        return
      }

      let total = 0;
      // const USDrate = this.rates()["USD"].sell_rate
      const baseCurrencyRate = this.rates()[this.baseCurrency] ? this.rates()[this.baseCurrency].sell_rate : 0
      
      for (let _wallet in this.wallet ) {
        const { balance: walletBalance } = this.wallet[_wallet];
        const planBalance = this.planBalances[_wallet] || 0;
        const { sell_rate } = this.rates()[_wallet] || { sell_rate: 0}

        total += ((walletBalance || 0) + planBalance )/ ( sell_rate || 1);
      }

      this.totalWalletBalance = total * baseCurrencyRate;
      this.totalWalletBalanceUSD = total;
    },

    // resetSlide() {
    //   const self = this;
    //   setTimeout( function() {
    //     const slides = document.querySelector('.asset-slide');
    //     if ( !slides ) {
    //       self.resetSlide();
    //       return;
    //     }
    //     try {
    //       self.slider = tns({
    //         container: '.asset-slide',
    //         items: 3,
    //         slideBy: 'page',
    //         autoplay: false,
    //         controlsPosition: "bottom",
    //         navPosition: "bottom",
    //         arrowKeys: false,
    //         controls: false,
    //         prevButton: false,
    //         nav: false,
    //         navAsThumbnails: false,
    //         autoplayHoverPause: true,
    //         autoplayButton: false,
    //         controlsContainer: false,
    //         navContainer: false,
    //         // freezable: false
    //       }).goTo(2)
    //     } catch (e) {}
    //   }, 1000);
    // },
    createTour(){
      this.tour = this.$shepherd({
        useModalOverlay: true
      });

      this.tour.addStep({
        title: "Welcome!",
        cancelIcon: {enabled: true},
        attachTo: { element: '#step_1', on: 'bottom' },
        text: 'Welcome to Redge Dashboard. Let me walk you through this amazing app',
        buttons: [
          {
            text: 'next',
            action: this.tour.next,
            classes: "btn btn-primary"
          }
        ]
      });

      this.tour.addStep({
        title: "Savings",
        cancelIcon: {enabled: true},
        attachTo: { element: '#step_2', on: 'bottom' },
        text: 'Click here to start saving in USD or NGN. We have nice plans',
        buttons: [
          {
            text: 'next',
            action: this.tour.next,
            classes: "btn btn-primary"
          }
        ],
        // scrollTo: {behavior: 'smooth', block: 'center'}
      });

      this.tour.addStep({
        title: "Loan",
        cancelIcon: {enabled: true},
        attachTo: { element: '#step_3', on: 'bottom' },
        text: 'Click here to take a loan. It is pretty fast',
        buttons: [
          {
            text: 'next',
            action: this.tour.next,
            classes: "btn btn-primary"
          }
        ]
      });

      this.tour.addStep({
        title: "Investment",
        cancelIcon: {enabled: true},
        attachTo: { element: '#step_4', on: 'bottom' },
        text: 'We have nice investment plans that yeilds upto 4% in a month',
        buttons: [
          {
            text: 'next',
            action: this.tour.next
          }
        ]
      });

      this.tour.addStep({
        title: "Wallet",
        cancelIcon: {enabled: true},
        attachTo: { element: '#step_5', on: 'bottom' },
        text: 'You can switch between the multi-wallet we have created for you.',
        buttons: [
          {
            text: 'next',
            action: this.tour.next
          }
        ]
      });

      this.tour.addStep({
        title: "Deposit",
        cancelIcon: { enabled: true},
        attachTo: { element: '#step_6', on: 'top' },
        text: 'Click here add money to your wallet.',
        buttons: [
          {
            text: 'next',
            action: this.tour.next
          }
        ]
      });

      this.tour.addStep({
        title: "Withdrawal",
        cancelIcon: {enabled: true},
        attachTo: { element: '#step_7', on: 'top' },
        text: 'You can withdraw your funds by clicking this button',
        buttons: [
          {
            text: 'next',
            action: this.tour.next
          }
        ]
      });

      this.tour.addStep({
        title: "Bills",
        cancelIcon: {enabled: true},
        attachTo: { element: '#step_8', on: 'top' },
        text: 'You can topup airtime and data here, buy power and pay for your TV cable.',
        buttons: [
          {
            text: 'Done!',
            action: this.tour.next
          }
        ]
      });
    
      window.localStorage.setItem("toured", "done")
    },

    handleNavChange(page) {
      this.switchAccount(page);
      this.$emit('pageChange', {page})
    }
  }
})
</script>

<style scoped>
.bg-card-primary {
  --bs-bg-opacity: 1;
  background-color: var(--jw-text-success, var(--bs-bg-opacity)) !important;
}

.wallet-icon>svg>path, .wallet-icon>svg>g>path{
  fill: rgba(var(--bs-success-rgb));
}

.nav-item {
  padding: 2px 0px
}

.nav-item button {
  padding: 6px 0px
}

.colorful-tab .tab-content {
  background-color: rgba(0, 0, 0, 0) !important
}

.colorful-tab .btn-warning, .shepherd-button, .bg_color_1 {
  background-color: var(--warning-color) !important;
  border-color: var(--warning-color) !important;
  background: var(--warning-color) !important;
  columns: var(--primary-color) !important;
}

.input-group-text.select-input {
  padding: 0rem;
  border: 0px solid #ced4da;
}

.colorful-tab .btn.btn-warning {
  color: var(--primary-color) !important;
}

.colorful-tab-card .btn {
  font-size: 12px;
}

.vas a {
  text-decoration: none !important;
}
.vas .link {
  color: var(--jw-text-success) !important;
}
.btn-primary:hover {
    color: #fff;
    /* background-color: #0b5ed7; */
    background-color: rgba(var(--bs-success-rgb));
    border-color: #0a58ca;
}
.dropdown-menu.show {
    background-color: rgba(var(--bs-success-rgb));
    color: white;
}
.dropdown-menu.show li a{
    color: white;
}
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
