<template>
    <modal :title="title || 'Filter transaction'" :id="id">
        <form>
            <div class="form-group position-relative">
                <input class="form-control form-control-clicked px-5" id="fund-amount" type="number" placeholder="Enter Name or Reference" v-model="query.search" >
                <div class="position-absolute form-control-prepend">
                  <i class="pi pi-search"></i>
                </div>
            </div>
            <div class="form-group">
                <select 
                    class="form-control form-control-clicked" 
                    id="transaction-type" 
                    placeholder="Airtime" v-model="query.category">
                    <option value="" disabled selected>Select Transaction Type</option>
                    <option v-for="(type, i) in transactionTypes" :key="i" :value="type">{{ snakeToTitleCase(type) }}</option>
                </select>
            </div>
            <div class="form-group">
                <select 
                    class="form-control form-control-clicked" 
                    id="transaction-status" 
                    placeholder="Successful" v-model="query.status">
                    <option value="" disabled selected>Select Transaction Status</option>
                    <option v-for="(status, i) in statusList" :key="i" :value="status">{{ snakeToTitleCase(status) }}</option>
                </select>
            </div>
            
            <app-button 
                @click="handleTransactionFilter" 
                :classes="'btn m-1 btn-sm btn-warning shadow'" 
                :title="'Apply Filter'"
            ></app-button>

            <app-button 
                @click="handleTransactionDownload" 
                :classes="'btn m-1 btn-sm btn-success shadow'" 
                :title="'Download'"
            ></app-button>
        </form>
    </modal>
</template>

<script>
    import Modal from '@/components/common/modal/Modal.vue';
    import AppButton from '@/components/common/form/AppButton.vue';
    import { snakeToTitleCase, transactionList as transactionTypes, statusList } from "@/utils"
    import bootstrapBundleMin from '@/assets/js/bootstrap.bundle.min';

    export default {
        name: 'ModalTransactionFilter',
        props: ['title', 'id'],
        components: {
            Modal,
            AppButton
        },
        data() {
            return {
                query: {},
                snakeToTitleCase,
                transactionTypes,
                statusList,
            }
        },
        methods: {
            handleTransactionFilter() {
                this.hideModal()
                this.$emit('filter', {...this.query, get_csv: false})
            },
            handleTransactionDownload() {
                this.hideModal()
                this.$emit('download', {...this.query, get_csv: true})
            },
            hideModal() {
                const thisModal = document.getElementById(this.id);
                const _thisModal = bootstrapBundleMin.Modal.getInstance(thisModal)
                _thisModal.hide();
            }
        }
    }
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
  