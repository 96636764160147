<template>
    <modal title="Payment Method" :id="id" title-icon="pi pi-wallet">
      <div class="text-left mb-4"> 
        <h6 class="capitalize">
          <i class="pi pi-warehouse"></i> {{ stage ? stage.split("_").join(" ") : 'Pick a funding method' }}
        </h6>
      </div>
      <div class="text-left" v-if="currency.symbol === 'NGN' && !stage">
        <ul class="px-0">
          <li>
            <i class="pi pi-arrow-right text-muted" style="font-size: 10px;"></i>
            <router-link class="btn m-1 btn-sm btn-warning shadow" 
              :to="{
                path: '/bank-transfer',
                query: getBankTransferParams,
                params: getBankTransferParams
              }" 
              @click="closeModal"
            >
              <i class="pi pi-building-columns" style="font-size: 11px;"></i> Bank Transfer 
            </router-link>
            <span class="small">to your redge account</span> <i class="pi pi-sun" style="font-size: 10px;"></i>
          </li>
          <li>
            <i class="pi pi-arrow-right text-muted" style="font-size: 10px;"></i>
            <button class="btn m-1 btn-sm btn-warning shadow" @click="stage=stages.USSD_DEPOSIT">
              <i class="pi pi-qrcode" style="font-size: 11px;"></i> USSD Code &nbsp;&nbsp;&nbsp;
            </button>
            <span class="small">dial to fund</span> <i class="pi pi-asterisk" style="font-size: 10px;"></i>
          </li>
          <li>
            <i class="pi pi-arrow-right text-muted" style="font-size: 10px;"></i>
            <button class="btn m-1 btn-sm btn-warning shadow" @click="stage=stages.WALLET_DEPOSIT">
              <i class="pi pi-wallet" style="font-size: 11px;"></i> Redge Wallet
            </button>
            <span class="small">your unique wallet number</span> <i class="pi pi-bolt" style="font-size: 10px;"></i>
          </li>
        </ul>
      </div>
      <div class="text-center" v-if="currency.symbol === 'USD'">
        <!-- <router-link class="btn m-1 btn-sm btn-warning shadow" to="/bank-transfer-usd" >
          ACH Bank Transfer
        </router-link> -->
        
        <form>
          <div class="form-group">
            <label class="form-label" for="fund-amount">Amount (USD)</label>
            <input class="form-control form-control-clicked" id="fund-amount" type="number" placeholder="100" v-model="v$.fund_amount.$model" >
            <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.fund_amount.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          
          <app-button @click="makePayment" :classes="'my-1 btn-warning w-100 shadow'" :title="'continue'"  :disabled="v$.fund_amount.$invalid" ></app-button>
        </form>
      </div>
      <div class="" v-if="stage === stages.USSD_DEPOSIT">
        <form>
          <div class="form-group position-relative">
            <!-- <label class="form-label text-left" for="fund-amount">Amount</label> -->
            <input 
              class="form-control form-control-clicked px-5" 
              id="fund-amount" 
              type="number" 
              placeholder="100" 
              v-model="v$.fund_amount.$model"

            />
            <div class="position-absolute form-control-prepend" style="top: 12.5px;">
              NGN
            </div>
            <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.fund_amount.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>

          <div class="form-group mb-3 position-relative" v-if="!v$.fund_amount.$invalid">
            <!-- <label class="form-label text-left" for="bank"></label> -->
            <select :disabled="loaders.fetchBanksWithUSSD" v-model="v$.bankUSSD.$model" class="form-control px-5" id="bank">
              <option value="" disabled selected>Select Bank</option>
              <option v-for="(c, i) in banksWithUSSD" :key="i" :value="c">{{ c.name }}</option>
            </select>
            <div class="position-absolute form-control-prepend">
              <i class="pi pi-building-columns"></i>
            </div>
          </div>
          <!-- :to="`/bank-transfer?amount=${fund_amount}&code=${bankUSSD.ussdTemplate}`"  -->
          <router-link 
            :to="{
                path: '/bank-transfer',
                query: getBankTransferParams,
                params: getBankTransferParams
            }"
            class="btn my-1 btn-warning w-100 shadow" 
            v-if="!v$.fund_amount.$invalid&&!v$.bankUSSD.$invalid" 
            @click="closeModal"
          >Continue</router-link>
        </form>
        <hr/>
        <span @click="stage=null" class="cursor-pointer">
          <i class="pi pi-arrow-left text-muted" style="font-size: 11px;"></i> Back
        </span>
      </div>
      <div class="" v-if="stage === stages.WALLET_DEPOSIT">
        <div>
          <p class="mb-0 text-muted" @click=clickToCopy(currency.identity)>
            <span v-if="currency.identity !== '--'"> {{ currency.symbol }} Wallet Number: {{ currency.identity }}</span>
            <span v-else class="spinner-border spinner-border-sm text-warning" role="status">
              <span class="sr-only"></span>
            </span>
            &nbsp;
            <i class="bi bi-files text-xs"></i>
          </p>
        </div>
        <hr/>
        <span @click="stage=null" class="cursor-pointer">
          <i class="pi pi-arrow-left text-muted" style="font-size: 11px;"></i> Back
        </span>
      </div>
    </modal>
</template>

<script>
    import bootstrapBundleMin from '@/assets/js/bootstrap.bundle.min';
    import { required, minLength } from '@vuelidate/validators';
    import Modal from '@/components/common/modal/Modal.vue';
    import { mapGetters, mapActions } from 'vuex';
    import useVuelidate from '@vuelidate/core';
    import { strings } from '@/mixins';
    import moment from 'moment'

    export default {
      name: 'ModalFundAccount',
      props: ['id', 'currency', 'account'],
      mixins: [strings],
      components: {
        Modal
      },
      setup () {
        return { v$: useVuelidate() }
      },
      validations() {
        return {
          fund_amount: { required, min: minLength(1) },
          bankUSSD: { required },
        }
      },
      computed: {
        ...mapGetters([
          "loaders", 
          "auth", 
          "banksWithUSSD"
        ]),
        getBankTransferParams() {
          const { account_name, account_number, bank_name, reference } = this.account || {}
          return {
            amount: this.fund_amount,
            code: this.bankUSSD ? this.bankUSSD.ussdTemplate : null,
            account_name, account_number, bank_name, reference
          }
        }
      },
      async mounted() {
        this.moment = moment;
        this.getBanksWithUSSD();
      },
      data() {
        return {
          fund_amount: null,
          bankUSSD: null,
          stage: "",
          stages: {
            USSD_DEPOSIT: "ussd_deposit",
            WALLET_DEPOSIT: "wallet_deposit"
          },
        }
      },
      methods: {
        ...mapActions([
          "getBanksWithUSSD",
          "confirmFlutterPayment",
        ]),
        makePayment() {
          const self = this;
          const paymentData = {
            tx_ref:(new Date()).getTime() + "-" + self.auth.id,
            amount: self.fund_amount + 0.5,
            currency: 'USD',
            payment_options: 'card,mobilemoney,ussd,banktransfer',
            meta: {
              'counsumer_id': self.auth.id,
              'consumer_mac': window.localStorage.getItem("uuid")
            },
            customer: {
              email: self.auth.email,
              name: self.auth.name
            },
            customizations: {
              title: 'Fund Wallet',
              description: "Fund redge USD wallet",
              logo: 'https://app.redge.ng/img/redge.3fd2356f.png'
            },
            callback: self.makePaymentCallback,
            onclose: self.closedPaymentModal
          }
          if ( self.auth.profile && self.auth.profile.phone ) {
            paymentData.customer.phonenumber = self.auth.profile.phone
          }
          self.$launchFlutterwave(paymentData)
        },
        makePaymentCallback(response) {
          console.log("Pay", response)
          const self = this;
          this.confirmFlutterPayment(response).then( res => {
            self.isSuccessful = true;
            self.$router.push({
              path: "/save-subscribe-success"
            });
            
          });
        },
        closeModal() {
          const thisModal = document.getElementById(this.id);
          const _thisModal = bootstrapBundleMin.Modal.getInstance(thisModal)
          _thisModal.hide();
        }
      }
    }
</script>

<style scoped>
</style>
  