<template>
    <modal :id="id" :title="`${cluster.name} chat room`">
      <div id="pannel">
        <div class="pannel-box">
          <div class="controls">
            <span>{{ cluster.code }}</span>
          </div>
          <div class="chat-container" id="chat-container">
            <ul class="chat">
              <template v-for="({message, user, attachments = [], status = {}, created_at}, i) in messages" :key="i">
                <li 
                  v-if="user.id === auth.uuid"
                  :class="`message right ${status.isSending ? 'sending' : ''} ${status.isFailed ? 'error' : ''}`">
                    <img class="logo" :src="user.image" alt="">
                    <p>
                      <small>{{ moment(new Date(created_at)).fromNow() }}</small>
                      <template v-if="attachments.length > 0">
                        <span v-for="(attachment, j) in attachments" :key="j">
                          <img class="message-file" @click="() => download(attachment.thumb_url)" :src="attachment.thumb_url" width="100px" alt="thumb-nail" />
                          <br/>
                        </span>
                      </template>
                      <template v-else>
                        {{ message }}
                      </template>
                    </p>
                </li>
                <li v-else :class="`message left ${status.isSending ? 'sending' : ''} ${status.isFailed ? 'error' : ''}`">
                  <img class="logo" :src="user.image" alt="">
                  <p>
                    <template v-if="attachments.length > 0">
                      <span v-for="(attachment, j) in attachments" :key="j">
                        <img class="message-file" @click="() => download(attachment.thumb_url)" :src="attachment.thumb_url" width="100px" alt="thumb-nail" />
                        <br/>
                      </span>
                    </template>
                    <template v-else>
                      {{ message }}
                    </template>
                    <small>{{ moment(new Date(created_at)).fromNow() }}</small>
                  </p>
                </li>
              </template>
            </ul>
          </div>
          <div class="form">
            <input 
              type="text" 
              id="message-input-feild" 
              class="text_input" 
              placeholder="Message..." 
              @keyup="(e) => chat.sendMessage(e)"
            />
            <span class="message-control">
              <span @click="() => $refs.attachmentInputFeild.click()">
                <input type="file" id="attachment-input-feild" ref="attachmentInputFeild" class="attachment" @change="uploadFile" />
                <i class="bi bi-paperclip text-lg"></i>
              </span>
            </span>
          </div>
        </div>
      </div>
    </modal>
</template>

<script>
    import Modal from '@/components/common/modal/Modal.vue'
    import useVuelidate from '@vuelidate/core';
    import { mapGetters, mapActions } from 'vuex';
    import Chat from '@/services/chat/chat';
    import { StreamChatProvider } from '@/services/chat/providers';
    import moment from 'moment'

    export default {
        name: 'ModalChat',
        props: ['cluster', 'id'],
        components: {
            Modal
        },
        watch: {
          '$store.state.auth': async function (auth) {
            const { clusters = [] } = auth;
            const { uuid: businessUUID } = clusters[0] || {}

            if ( businessUUID && !this.chat ) {
              this.chat = new Chat(new StreamChatProvider());

              const user = await this.chat.user();
              this.chat.join({cid: businessUUID, userId: user.id}).then()
            }
          }
        },
        setup () {
            return { v$: useVuelidate() }
        },
        validations() {
            return {

            }
        },
        computed: {
          ...mapGetters([
                "loaders", "messages", "auth"
            ]),
        },
        async mounted() {
          this.moment = moment;
        },
        data() {
            return {
            }
        },
        methods: {
          ...mapActions([
          ]),
          uploadFile(e){this.chat.sendImage(e)},
          download(url){
            const filename = `Redge-File-${moment().format('YYYY-MM-DD-h-mm')}`
            fetch(url)
              .then(response => response.blob())
              .then(blob => {
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = filename;
              link.click();
            })
            .catch(console.error);
          },
        }
    }
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Lato');
#pannel {
  background: url('https://www.shutterstock.com/image-vector/social-media-sketch-vector-seamless-600nw-1660950727.jpg');
  background-size: cover;
  background-position: center	center;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  font-family: 'Lato', sans-serif;
  margin: 0 0;
  height: 100%;
  overflow: hidden;
}
.pannel-box .controls {
  top: 0;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #fff;
  z-index: 1000;
  font-size: 10px;
}
.pannel-box .controls button {
  color: white;
  background-color: rgb(235, 12, 12);
  border: none;
  padding: 2px 10px;
  border-radius: 1px;
}
.pannel-box .controls span {
  padding: 2px 10px;
  font-size: 12px;
}
.pannel-box .chat-container {
  background-color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  padding: 15px;
  position: relative;
  height: 356px;
  max-width: 100%;
  width: 100%;
}
.pannel-box .chat-container .chat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  height: 100%;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.pannel-box .chat-container .chat::-webkit-scrollbar {
  display: none;
}
.pannel-box .chat-container .chat .message {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50px;
  box-shadow: 0px 15px 5px 0px rgba(0,0,0,0.5);
  position: relative;
  margin-bottom: 15px;
  font-size: 12px;
  max-width: 100%;
}
.pannel-box .chat-container .chat .message.sending {
  background-color: rgba(210, 210, 210, 0.9);
}
.pannel-box .chat-container .chat .message.error{
  background-color: rgba(255, 210, 210, 0.9);
}
.pannel-box .chat-container .chat .message.left{
  padding: 10px 20px 10px 50px;
}
.pannel-box .chat-container .chat .message.left small {
  margin-left: 0.5rem;
  color: #999;
  font-size: 8px;
}
.pannel-box .chat-container .chat .message.right {
  align-self: flex-end;
  padding: 10px 50px 10px 20px;
}
.pannel-box .chat-container .chat .message.right .logo {
  left: auto;
  right: 10px;
}
.pannel-box .chat-container .chat .message.right small {
  margin-right: 0.5rem;
  color: #999;
  font-size: 8px;
}
.pannel-box .chat-container .chat .message .logo {
  border-radius: 50%;
  box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.7);
  object-fit: cover;
  position: absolute;
  left: 10px;
  top: -10px;
  width: 32px;
  height: 32px;
}
.pannel-box .chat-container .chat .message p {
  margin: 0;
}
.pannel-box .chat-container .chat .message .message-file {
  cursor: pointer;
}
.pannel-box .form {
  position: relative;
  width: 100%;
}
.pannel-box .form .text_input {
  font-size: 16px;
  position: relative;
  left: 0;
  right: 0;
  padding: 10px 15px;
  padding-right: 40px;
  width: 100%;
  color: #666;
}
.pannel-box .form .message-control {
  position: absolute;
  top: 6px;
  right: 10px;
}
.pannel-box .form .message-control .attachment {
  display: none;
}
.pannel-box {
  width: 100%;
  position: relative;
}
</style>
  