export const setActiveFooterNav = (
  state, activeFooterNav
) => {
  state.activeFooterNav = activeFooterNav;
}

export const appConfig = (s, config) => {
  if (config && typeof config === "object") {
    localStorage.setItem("appConfig", JSON.stringify(config))
  }
  s.appConfig = config
};

export const fetchTenant = (
  state, tenant
) => {
  state.tenant = tenant;
}


const AppMutation = {
  setActiveFooterNav,
  appConfig,
  fetchTenant
};

export default AppMutation;
