<template>
  <modal :id="id" :title="title" titleIcon="pi pi-clock">
      <div>
        <div v-if="loaders.saveWalletRequest" class="my-5">
          <div class="my-4 text-center">hang in a sec, we are creating this request...</div>
          <br/>
          <div class="mb-4 text-center">
            <div class="spinner-border" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        </div>
        <success-notification 
          v-if="successful"
          title="All done" 
          message="Your request has been create, kindly wait for approval from members"
          @close="$emit('menu', 'request')"
          nextPageName="view request"
        ></success-notification>
        <div v-else-if="!loaders.saveWalletRequest && !successful" class="py-5 text-center text-danger">
          <i class="pi pi-exclamation-circle"></i> this request has failed. Kindly try again!
        </div>
      </div>
    </modal>
</template>

<script>
    import SuccessNotification from '@/components/common/notification/SuccessNotification.vue'
    import Modal from '@/components/common/modal/Modal.vue';
    import AppButton from '@/components/common/form/AppButton.vue';
    import { mapGetters } from 'vuex';

    export default {
      name: 'ModalBusinessRequestProcess',
      props: ['id', 'title', 'successful'],
      components: {
        Modal,
        AppButton,
        SuccessNotification
      },
      computed: {
        ...mapGetters(["loaders"])
      },
      data() {
        return {
        }
      },
      methods: {
      }
    }
</script>

<style scoped>
</style>
  