<template>
    <modal :title="title || stage?.split('_').join(' ') || 'Join Cluster'" :id="id" title-icon="pi pi-users">
        <transition name="bounce">
            <div v-if="!stage">
                <h6 class="mb-4">
                    Join Cluster By Code <i class="pi pi-verified"></i>
                </h6>
                <div class="form-group">
                    <label class="form-label" for="userNameInput">Enter cluster code</label>
                    <input class="form-control form-control-clicked" id="userNameInput" v-model="v$.businessCode.$model" type="text" placeholder="333-AAA">
                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.businessCode.$errors" :key="index">
                        <div class="error-msg">{{ error.$message }}</div>
                    </div>
                </div>
                <div class="text-center my-1 mb-8">
                    <app-button 
                    @click="findBusiness" 
                    title="Preview"
                    icon="bi-search"
                    :disabled="loaders.fetchBusinessByCode || v$.businessCode.$invalid"
                    :loading="loaders.fetchBusinessByCode"
                    ></app-button>
                </div>
                <div class="input-errors text-xs text-danger mt-2" v-for="([key, error], index) of Object.entries(errors)" :key="index">
                    <div class="error-msg">
                        <i class="pi pi-exclamation-triangle" style="font-size: 11px;"></i> {{ key }}: {{ error }}
                    </div>
                </div>
                <hr/>
                <p class="text-muted text-center">Or</p>
                <div>
                    <button class="btn btn-sm btn-warning shadow" @click="stage = stages.FIND_BUSINESS_STATE">
                        <i class="pi pi-search-plus" style="font-size: 11px;"></i> Find Cluster
                    </button> <span class="small text-muted"> <i class="pi pi-lightbulb"></i> find a cluster around you</span> or 
                    <span @click="stage = stages.CREATE_BUSINESS" class="text-success">create a new one.</span>
                </div>
            </div>
        </transition>
        <transition name="bounce">
            <div v-if="stage === stages.PREVIEW_BUSINESS" class="">
                <h6>Cluster Details: </h6>
                <div class="w-full flex">Code: <strong>{{ business.code }}</strong> &nbsp;
                <i class="pi pi-copy" @click=clickToCopy(business.code)></i>
                </div>
                <div class="w-full flex">
                    Name: <strong>{{ business.name }}</strong> &nbsp;
                    <i class="pi"
                        :class="{
                            'pi-verified text-success': business.status === 'APPROVED',
                            'pi-exclamation-circle text-warning': business.status === 'PARTIALLY_BLOCKED',
                            'pi-ban text-danger': business.status === 'BLOCKED'
                        }"
                    ></i>
                </div>
                <div class="w-full flex">
                    Location: <span>{{ business.city }}, {{ business.state }}</span> <i class="pi pi-map-marker"></i>
                </div>
                <div class="w-full flex">
                    Ref: <span class="small">{{ business.uuid }}</span> &nbsp;
                    <i class="pi pi-copy" @click=clickToCopy(business.uuid)></i>
                </div>
                <div class="w-full flex">
                    Links: 
                    <a v-if="business.whatsapp_link" :href="business.whatsapp_link" target="_blank">
                        <i class="bi bi-whatsapp text-xs"></i>
                    </a>
                    <a v-else-if="business.support_email" :href="`mailto:${business.support_email}`" target="_blank">
                        <i class="bi bi-envelope text-xs"></i>
                    </a>
                </div>
                <div class="text-center mt-4 mb-8">
                    <app-button 
                    @click="joinCluster" 
                    title="Confirm & Join"
                    :disabled="loaders.joinBusiness || v$.businessCode.$invalid"
                    :loading="loaders.joinBusiness"
                    ></app-button>
                </div>
                <div class="input-errors text-xs text-danger mt-2" v-for="([key, error], index) of Object.entries(errors)" :key="index">
                    <div class="error-msg">
                        <i class="pi pi-exclamation-triangle" style="font-size: 11px;"></i> {{ key }}: {{ error }}
                    </div>
                </div>
                <hr/>
                <div @click="stage=null"><i class="pi pi-arrow-left text-muted" style="font-size: 11px;"></i> back</div>
            </div>
        </transition>
        <transition name="bounce">
            <success-notification 
            v-if="stage === stages.JOIN_PLAN_COMPLETED"
            title="Successful" 
            message="Thank You!" 
            nextPageName="Done"
            @close="hideModal"
            ></success-notification>
        </transition> 
        <transition name="slide-fade">
            <div v-if="stage === stages.FIND_BUSINESS_STATE">
                <div class="alert alert-warning alert-dismissible fade show">
                    You have to join a cluster near you in order to get a loan.
                </div>

                <div v-if="loaders.fetchBusinessAvailableState" class="spinner-border spinner-border-sm text-warning" role="status">
                    <span class="sr-only"></span>
                </div>
                <div v-else class="overflow-auto mb-8" style="height: 400px;">
                    <h6 class="mb-4">Please select a state</h6>
                    <div v-for="(state, i) in businessStates" :key="i">
                        <div @click="handleSelectedState(state)" v-if="state" :class="{'text-warning': selectedState === state}">
                            <div class="d-flex align-items-center justify-content-between">
                                <p class="mb-1 ">
                                    <i class="pi pi-arrow-right" style="font-size: 10px;"></i> {{ state }}
                                </p>
                                <div>
                                    <i class="bi bi-bank text-xs"></i>
                                </div>
                            </div>
                            <hr/>
                        </div>
                    </div>
                    <div class="text-center my-1 mb-8">
                        <app-button 
                        @click="handleContinueFindBusiness" 
                        :title="'Continue'"
                        :disabled="!selectedState"
                        :loading="loaders.fetchBusinessAvailableState"
                        ></app-button>
                    </div>
                </div>
                <hr/>
                <small>
                    <i class="bi bi-info-circle text-warning"></i> <i>Make sure you select the state you are currently operating in, to enhance easy acceptance into a cluster.</i>
                </small>
            </div>
        </transition>
        <transition name="slide-fade">
            <div v-if="stage === stages.FIND_BUSINESSES">
                <div v-if="loaders.fetchBusinessByState">
                    <div class="spinner-border spinner-border-sm text-warning" role="status">
                        <span class="sr-only"></span>
                    </div> <i class="text-muted"> searching for clusters, please wait...</i>
                </div>
                <div v-else class="overflow-auto mb-8" style="height: 400px;">
                    <h6>Clusters in {{ selectedState }}</h6>
                    <div class="alert alert-warning alert-dismissible fade show small">
                        <h6><i class="bi bi-info-circle text-warning"></i> Instructions to join a cluster</h6>
                        <ul class="px-2">
                            <li v-for="(instruction, i) in instructions" :key="i">
                                <i class="pi pi-asterisk text-muted"></i> {{ instruction }}
                            </li>
                        </ul>
                    </div>
                    <div class="my-5" v-for="(business, i) in businesses.data || []" :key="i">
                        <div v-if="business">
                            <div class="d-flex align-items-center justify-content-between text-secondary">
                                <p class="mb-1 ">{{ business.name }} | {{ business.lga }}</p>
                                <div>
                                    <a v-if="business.whatsapp_link" :href="business.whatsapp_link" target="_blank">
                                        <i class="bi bi-whatsapp text-xs"></i>
                                    </a>
                                    <a v-else-if="business.support_email" :href="`mailto:${business.support_email}`" target="_blank">
                                        <i class="bi bi-envelope text-xs"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <hr/>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-between mt-2">
                    <p @click="() => stage=stages.FIND_BUSINESS_STATE">
                    <i class="pi pi-arrow-left text-muted" style="font-size: 11px;"></i>  back
                    </p>
                    <button class="btn btn-sm btn-warning shadow" @click="() => stage=null">
                        <i class="pi pi-lightbulb" style="font-size: 10px;"></i> I have a cluster code
                    </button>
                </div>
            </div>
        </transition>

        <transition name="bounce">
            <div v-if="stage === stages.CREATE_BUSINESS">
                <h6 class="mb-4">
                    Create a new Cluster <i class="pi pi-users"></i>
                </h6>
                <div class="form-group">
                    <label class="form-label" for="clusterNameInput">Enter a cluster name</label>
                    <input class="form-control form-control-clicked" id="clusterNameInput" v-model="v$.business.name.$model" type="text" placeholder="Asute Group">
                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.business.name.$errors" :key="index">
                        <div class="error-msg">{{ error.$message }}</div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-label" for="clusterWhatsappInput">Enter whatsapp group link</label>
                    <input class="form-control form-control-clicked" id="clusterWhatsappInput" v-model="v$.business.whatsapp_link.$model" type="text" placeholder="https://chat.whatsapp.com/code">
                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.business.whatsapp_link.$errors" :key="index">
                        <div class="error-msg">{{ error.$message }}</div>
                    </div>
                </div>
                <div class="text-center my-1 mb-8">
                    <app-button 
                    @click="createCluster" 
                    title="Create Cluster"
                    icon="pi pi-user-plus"
                    :disabled="loaders.createCluster || v$.business.$invalid"
                    :loading="loaders.createCluster"
                    ></app-button>
                </div>
                <div class="input-errors text-xs text-danger mt-2" v-for="([key, error], index) of Object.entries(errors)" :key="index">
                    <div class="error-msg">
                        <i class="pi pi-exclamation-triangle" style="font-size: 11px;"></i> {{ key }}: {{ error }}
                    </div>
                </div>
                <hr/>
                <div @click="stage = null" class="text-muted">
                    <i class="pi pi-arrow-left" style="font-size: 11px;"></i> back
                </div>
            </div>
        </transition>
    </modal>
</template>

<script>
    import Modal from '@/components/common/modal/Modal.vue'
    import useVuelidate from '@vuelidate/core';
    import { createToaster } from "@meforma/vue-toaster";
    import { mapGetters, mapActions } from 'vuex';
    import { required, minLength } from '@vuelidate/validators';
    import SuccessNotification from '@/components/common/notification/SuccessNotification.vue';
    import AppButton from '@/components/common/form/AppButton.vue';
    const toaster = createToaster({ /* options */ });
    import bootstrapBundleMin from '@/assets/js/bootstrap.bundle.min';
    import { strings } from '@/mixins';

    export default {
        name: 'ModalBusiness',
        props: ['title', 'id', 'initStage'],
        mixins: [ strings ],
        components: {
            Modal,
            AppButton,
            SuccessNotification
        },
        setup () {
            return { v$: useVuelidate() }
        },
        validations() {
            return {
                businessCode: {required, min: minLength(6)},
                business: {
                    name: { required },
                    whatsapp_link: { required }
                }
            }
        },
        async mounted() {
            this.stage = null
            this.selectedState = ""
            this.businessCode = ""
            if (this.initStage) {
                this.stage = this.initStage
            }
            await this.fetchBusinessAvailableState()
        },
        data() {
            return {
                selectedState: "",
                business: {},
                errors: {},
                businessCode: "",
                stage: null,
                stages: {
                    JOIN_PLAN_COMPLETED: "completed",
                    PREVIEW_BUSINESS: "preview_business",
                    FIND_BUSINESS_STATE: "select_cluster_state",
                    FIND_BUSINESSES: "select_cluster",
                    CREATE_BUSINESS: "create_business"
                },
                instructions: [
                    "Click on the whatsapp icon to join whatsapp group or use email",
                    "Message or email the admin for the cluster code",
                    "Complete further verification as requested by the admin",
                    "Click on the 'I have a cluster code' button to continue"
                ]
            }
        },
        computed: {
            ...mapGetters([
                "loaders",
                "auth",
                "businessStates",
                "businesses"
            ]),
        },
        methods: {
            ...mapActions([
                "fetchBusinessAvailableState",
                "fetchBusinessByState",
                "fetchBusinessByCode",
                "joinBusiness",
                "createBusiness"
            ]),
            findBusiness() {
                if ( !this.businessCode ) {
                    this.errors = {message: "You cannot subscribe a user to this cluster"}
                    return
                }
                this.errors = {}
                this.fetchBusinessByCode(this.businessCode).then (r => {
                    toaster.success("Kindly confirm cluster " + r.name + ".")
                    this.stage = this.stages.PREVIEW_BUSINESS
                    this.business = r;
                }).catch( e => {
                    const { message } = e
                    this.errors = { message }
                });
            },
            joinCluster() {
                if ( !this.businessCode ) {
                    this.errors = {message: "You cannot subscribe a user to this cluster"}
                    return
                }
                const self = this
                this.joinBusiness({
                    user: this.auth.id, 
                    code: this.businessCode
                }).then (r => {
                    self.stage = self.stages.JOIN_PLAN_COMPLETED
                    toaster.success("You have joined " + r.business.name + " cluster. Kindly reload.")
                }).catch( e => {
                    const { message } = e
                    this.errors = { message }
                });
            },
            hideModal() {
                const thisModal = document.getElementById(this.id);
                const _thisModal = bootstrapBundleMin.Modal.getInstance(thisModal)
                _thisModal.hide();
                this.stage = null
            },
            async handleSelectedState (state) {
                this.selectedState = state;
                this.handleContinueFindBusiness()
            },
            async handleContinueFindBusiness() {
                this.stage = this.stages.FIND_BUSINESSES
                await this.fetchBusinessByState({state: this.selectedState})
            },
            async createCluster() {
                const { profile, email: support_email } = this.auth
                const { 
                    address, 
                    phone, 
                    city, 
                    postal_code, 
                    state, 
                    lga, 
                    landmark, 
                    country 
                } = profile

                if ( !address || !phone ) {
                    toaster.error('Kindly complete your profile to create a cluster')
                    return;
                }

                const payload = {
                    name: this.business.name, 
                    support_email, 
                    // logo: '', 
                    address, 
                    phone,
                    city,
                    postal_code,
                    state, 
                    lga, 
                    landmark, 
                    country, 
                    // is_live: '', 
                    whatsapp_link: this.business.whatsapp_link,
                    is_cluster: true
                }

                try {
                    await this.createBusiness(payload)
                    this.stage = this.stages.JOIN_PLAN_COMPLETED
                } catch (error) {
                    const { message = "An error occured!" } = error
                    this.errors = { message }
                }
            }
        }
    }
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}



.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

</style>
  