<template>
  <div class="transaction card">
    <div class="card-header">
      <h6 class="fw-bold"><i class="pi pi-credit-card"></i> {{ topic || 'Clusters' }}</h6>
    </div>
    <div class="card-body" v-if="clusters && clusters.length > 0">
      <div class="accordion accordion-flush accordion-style-one" id="accordionStyle1">
        <!-- Single Accordion -->
        <div class="accordion-item border-bottom border-success mb-2 py-2" v-for="(cluster, i) in clusters" :key="i">
          <div class="accordion-header" :id="`accordion${i}`">
            <div data-bs-toggle="collapse" :data-bs-target="`#accordionStyleCol${i}`" aria-expanded="false" :aria-controls="`accordionStyleCol${i}`" class="collapsed row">
              <div class="col-1">
               <i class="pi pi-users"></i>
              </div>
              <div class="d-flex justify-content-between text-xs col-10">
                <p class="text-xs">
                  <b
                  :class="{
                    'text-success': cluster.code === activeCluster.code
                  }"
                  >{{ cluster.name }}</b>
                  <br>
                  <small>
                    {{ cluster.address }}
                  </small>
                </p>
                <p>
                  {{ moment(cluster.created_at).format("MMM DD, YY [at] HH:mm a") }}
                </p>
              </div>
              <div class="col-1">
                <i class="bi bi-chevron-down"></i>
              </div>
            </div>
          </div>
          
          <div class="accordion-collapse collapse" :id="`accordionStyleCol${i}`" :aria-labelledby="`accordion${i}`" data-bs-parent="#accordionStyle1" style="">
            <div class="accordion-body">
              <p class="mb-0">
                
                <table class="table mb-0 table-hover">
                  <tbody>
                    <tr>
                      <td>Code</td>
                      <td>
                        <span v-if="cluster.user_id === auth.id" @click="copyShareDetails(cluster)">{{ cluster.code }} <i class="pi pi-share-alt" style="font-size: 10px;"></i></span>
                        <span v-else>*******</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>{{ cluster.status || 'PENDING' }}</td>
                    </tr>
                    <tr>
                      <td>Address</td>
                      <td>{{ cluster.address }} {{ cluster.lga }} {{ cluster.city }} {{ cluster.state }}</td>
                    </tr>
                    <tr>
                      <td>Links</td>
                      <td>
                        <a v-if="cluster.whatsapp_link" :href="cluster.whatsapp_link" target="_blank">
                            <i class="bi bi-whatsapp text-xs"></i>
                        </a>
                        <a v-else-if="cluster.support_email" :href="`mailto:${cluster.support_email}`" target="_blank">
                            <i class="bi bi-envelope text-xs"></i>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Cluster Members</td>
                      <td>
                        {{ cluster.customerCount }}
                      </td>
                    </tr>
                    <tr>
                      <td>Date Created</td>
                      <td>
                        {{ moment(cluster.created_at).format("ddd MMM DD, YYYY [at] HH:mm a") }}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="d-flex mt-2">
                  <button 
                    class="btn btn-sm btn-success shadow"
                    @click="handleMakeActive(cluster)"
                  >
                    <i class="pi pi-flag-fill" style="font-size: 11px;"></i> Select Cluster
                  </button>&nbsp;
                  <button 
                    class="btn btn-sm btn-warning shadow"
                    @click="handleExit(cluster)"
                    :disabled="loaders.fetchWalletPolicies"
                  >
                    <i class="pi pi-sign-out" style="font-size: 11px;"></i> Request Exit
                  </button>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body" v-else-if="loaders.fetchAuth">
      <div class="d-flex flex-column justify-content-center text-center">
        <div class="my-4 text-center">loading clusters</div>
        <br/>
        <div class="mb-4 text-center">
          <div class="spinner-border" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body" v-else>
      <div class="text-center text-muted">
        <i class="pi pi-exclamation-circle"></i>
        You do not belong to any cluster yet. Click the join <i class="pi pi-plus" style="font-size: 10px;"></i> 
        button above to join a cluster
        <br/>
        <br/>
        <img src="../../../assets/images/common/empty.png" />
      </div>
    </div>

    <modal title="Exit Cluster" id="cluster-exit-modal" title-icon="pi pi-sign-out">
      <h6>Kindly confirm your exit request</h6>

      <p>Name: <strong>{{ activeCluster.name }}</strong></p>
      <p>Date: <strong>{{ moment(new Date).format("ddd MMM DD, YYYY [at] HH:mm a") }}</strong></p>

      <form>
          <div class="form-group">
            <label class="form-label" for="bvn-data">Reason (Optional)</label>
            <input 
              class="form-control form-control-clicked" 
              type="text" 
              v-model="comment"
              placeholder="Your comment..."
            >
          </div>
          <app-button 
            @click="exitCluster" 
            classes="my-1 btn-warning w-100 shadow" 
            title="Request Exit" 
            :loading="loaders.saveWalletRequest" 
            :disabled="loaders.saveWalletRequest" 
          >
          </app-button>
        </form>
    </modal>
    <modal-business-request 
      id="business-request-2" 
      :request="activeRequest || {}"
      @continue="handleContinueRequest"
    ></modal-business-request>
    <modal-business-request-process 
      :title="`Creating ${requestTitle}...`"
      id="request-completion-modal-2"
      :successful="isRequestSuccessful"
      @menu="$emit('menu', 'request')"
    >
    </modal-business-request-process>
  </div>
</template>

<script>
// @ is an alias to /src
import Modal from '@/components/common/modal/Modal.vue';
import AppButton from '@/components/common/form/AppButton.vue';
import ModalBusinessRequestProcess from '@/components/common/modal/ModalBusinessRequestProcess.vue'
import ModalBusinessRequest from '@/components/common/modal/ModalBusinessRequest.vue'
import { walletRequest, strings } from '@/mixins'
import moment from 'moment'
import { mapGetters, mapMutations } from 'vuex'
import bootstrapBundleMin from '@/assets/js/bootstrap.bundle.min';

export default {
  name: 'ClusterList',
  props: ['clusters', 'topic'],
  mixins: [walletRequest, strings],
  components: {
    Modal,
    AppButton,
    ModalBusinessRequest,
    ModalBusinessRequestProcess
  },
  data() {
    return {
      activeCluster: {},
      comment: null,
      processingModalId: "request-completion-modal-2",
      modalInfoId: "business-request-2"
    }
  },
  computed: {
    ...mapGetters([
      "loaders",
      "cluster",
      "auth"
    ]),
  },
  created() {
    this.moment = moment;
  },
  mounted() {
    this.activeCluster = this.cluster
  },
  methods: {
    ...mapMutations(["activateCluster"]),
    handleMakeActive(cluster) {
      this.activeCluster = cluster
      this.activateCluster(cluster)
      this.$emit('cluster', cluster)
    },
    handleExit(cluster) {
      this.activeCluster = cluster
      this.handleRequestClick('EXIT_REQUEST', 'cluster-exit-modal')
    },
    exitCluster() {
      const exitClusterConfirmationModal = document.getElementById('cluster-exit-modal');
      const _exitClusterConfirmationModal = bootstrapBundleMin.Modal.getInstance(exitClusterConfirmationModal)
      _exitClusterConfirmationModal.hide();
      this.requestBusinessRequest({
        code: this.activeCluster.code,
        user_id: this.auth.id,
        EVENT_TYPE: "EXIT",
        comment: this.comment
      })
    },
    copyShareDetails(cluster) {
      const text = `Join my cluster with this code ${cluster.code} via ${window.location.href}`;
      this.clickToCopy(text)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.transaction.card, .accordion-item {
  background-color: transparent !important
}
.transaction.card {
  border: none;
}
.card-header h6 {
  color: var(--jw-text-success) !important;
}

[data-theme=dark] body .card-header h6 {
    color: #ffffff !important;
}

[data-theme=dark] body .card-header {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>
