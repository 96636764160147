<template>
  <div class="transaction card">
    <div class="card-header">
      <h6 class="fw-bold"><i class="pi pi-credit-card"></i> {{ topic || 'Cluster Members' }}</h6>
    </div>
    <div class="card-body" v-if="members && members.length > 0">
      <div class="accordion accordion-flush accordion-style-one" id="accordionStyle1">
        <!-- Single Accordion -->
        <div class="accordion-item border-bottom border-success mb-2 py-2" v-for="(member, i) in members" :key="i">
          <div class="accordion-header" :id="`accordion${i}`">
            <div aria-expanded="false" class="collapsed row">
              <div class="col-1">
               <i class="pi pi-user"></i>
              </div>
              <div class="d-flex justify-content-between text-xs col-11">
                <p class="text-xs">
                  <b
                  :class="{
                    'text-success': cluster.user_id === member.id
                  }"
                  >{{ member.name }}</b>
                  <!-- <br>
                  <small>
                    {{ member.email }}
                  </small> -->
                </p>
                <p>
                  <!-- {{ moment(cluster.created_at).format("MMM DD, YY [at] HH:mm a") }} -->
                  <i class="pi pi-phone"></i>
                </p>
              </div>
              <!-- <div class="col-1">
                <i class="pi pi-phone"></i>
              </div> -->
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <div class="card-body" v-else-if="loaders.fetchBusinessById">
      <div class="d-flex flex-column justify-content-center text-center">
        <div class="my-4 text-center">loading members</div>
        <br/>
        <div class="mb-4 text-center">
          <div class="spinner-border" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body" v-else>
      <div class="text-center text-muted">
        <i class="pi pi-exclamation-circle"></i>
        You do not belong to any cluster member yet. Share your cluster code with potential members to join.
        <br/>
        <br/>
        <img src="../../../assets/images/common/empty.png" />
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'

export default {
  name: 'ClusterMemberList',
  props: ['members', 'topic'],
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
      "loaders",
      "cluster",
      "auth"
    ]),
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.transaction.card, .accordion-item {
  background-color: transparent !important
}
.transaction.card {
  border: none;
}
.card-header h6 {
  color: var(--jw-text-success) !important;
}

[data-theme=dark] body .card-header h6 {
    color: #ffffff !important;
}

[data-theme=dark] body .card-header {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>
