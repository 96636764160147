<template>
  <div class="transaction card">
    <div class="card-header">
      <h6 class="fw-bold"><i class="pi pi-clock"></i> {{ topic || 'Pending Requests' }}</h6>
    </div>
    <div class="card-body" v-if="requests && requests.data && requests.data.length > 0">
      <div class="accordion accordion-flush accordion-style-one" id="accordionStyle1">
        <!-- Single Accordion -->
        <div class="accordion-item border-bottom border-success mb-2 py-2" v-for="(request, i) in requests.data" :key="i">
          <div class="accordion-header" :id="`accordion${i}`">
            <div 
              data-bs-toggle="collapse" 
              :data-bs-target="`#accordionStyleCol${i}`" 
              aria-expanded="false" 
              :aria-controls="`accordionStyleCol${i}`" 
              class="collapsed row"
              @click="handleToggleRequest(request)"
            >
              <div class="col-1">
               <i class="pi pi-users"></i>
              </div>
              <div class="d-flex justify-content-between text-xs col-10">
                <p class="text-xs">
                  <b
                  class="text-success"
                  >{{ request.type }}</b>
                  <br>
                  <small>
                    {{ request.user?.name }}
                  </small>
                </p>
                <p class="text-right">
                  {{ moment(request.created_at).format("MMM DD, YY [at] HH:mm a") }}
                </p>
              </div>
              <div class="col-1">
                <i class="bi bi-chevron-down"></i>
              </div>
            </div>
          </div>
          
          <div class="accordion-collapse collapse" :id="`accordionStyleCol${i}`" :aria-labelledby="`accordion${i}`" data-bs-parent="#accordionStyle1" style="">
            <div class="accordion-body">
              <div class="mb-0">
                <table class="table mb-0 table-hover">
                  <tbody>
                    <tr>
                      <td>Type</td>
                      <td>{{ request.type }}</td>
                    </tr>
                    <tr>
                      <td>From</td>
                      <td>{{ request.user?.name }} <br/>
                        <small>{{ request.user?.phone }}</small>
                      </td>
                    </tr>
                    <tr v-if="getAmount(request)">
                      <td>Amount</td>
                      <td>NGN {{ getAmount(request) }}</td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>{{ request.status }}</td>
                    </tr>
                    <tr>
                      <td>Date Created</td>
                      <td>
                        {{ moment(request.created_at).format("ddd MMM DD, YYYY [at] HH:mm a") }}
                      </td>
                    </tr>
                    <tr v-if="request.has_decided">
                      <td>Decision</td>
                      <td>
                        <span v-if="request.decision">Authorized</span>
                        <span v-else>Declined</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Vote Count</td>
                      <td>
                        {{ request.votes || 0 }}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="d-flex mt-2" v-if="auth.id !== request.user_id && ['DECLINED', 'COMPLETED'].includes(request.status)">
                  <app-button 
                    classes="btn btn-sm btn-success shadow"
                    @click="handleRequestDecision(request, true)"
                    :disabled="loaders.voteWalletRequest || loaders.getWalletRequest || request.has_decided"
                    :loading="loaders.voteWalletRequest || loaders.getWalletRequest"
                    icon="pi pi-verified"
                    title="Authorize"
                  />&nbsp;
                  <app-button 
                    classes="btn btn-sm btn-danger shadow" 
                    @click="handleRequestDecision(request, false)"
                    :disabled="loaders.voteWalletRequest || loaders.getWalletRequest || request.has_decided"
                    :loading="loaders.voteWalletRequest || loaders.getWalletRequest"
                    icon="pi pi-times-circle"
                    title="Decline"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body" v-else-if="loaders.fetchWalletRequests">
      <div class="d-flex flex-column justify-content-center text-center">
        <div class="my-4 text-center">loading cluster requests</div>
        <br/>
        <div class="mb-4 text-center">
          <div class="spinner-border" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body" v-else>
      <div class="text-center text-muted">
        <i class="pi pi-exclamation-circle"></i>
        There are no pending request in this cluster.
        <br/>
        <br/>
        <img src="../../../assets/images/common/empty.png" />
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import AppButton from '@/components/common/form/AppButton.vue';
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });

export default {
  name: 'ClusterRequest',
  props: ['requests', 'topic'],
  components: {
    AppButton,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
      "loaders", 
      "auth"
    ]),
  },
  created() {
    this.moment = moment;
  },
  methods: {
    ...mapActions([
      "voteWalletRequest",
      "getWalletRequest"
    ]),
    async handleRequestDecision(request, vote) {
      try {
        await this.voteWalletRequest({id: request.id, decision: vote})
      } catch (e) {
        const { message = "Something went wrong"} = err
        toaster.warning(message)
      }
    },
    handleToggleRequest(request) {
      this.getWalletRequest(request.id)
    }, 
    getAmount(request) {
      if (!request || !request.payload) return 0;
      const { payload } = request
      const payloadJSON = JSON.parse(payload)
      if (!payloadJSON.amount) return 0

      return payloadJSON.amount
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.transaction.card, .accordion-item {
  background-color: transparent !important
}
.transaction.card {
  border: none;
}
.card-header h6 {
  color: var(--jw-text-success) !important;
}

[data-theme=dark] body .card-header h6 {
    color: #ffffff !important;
}

[data-theme=dark] body .card-header {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>
