const { pathname } = window.location
const activeFooterNav = pathname ? pathname.startsWith("/") ? pathname.substring(1) : pathname : "dashboard"

const AppState = {
  activeFooterNav,
  toast: false,
  appConfig: {},
  tenant: {}
};

export default AppState;
