import { mapActions, mapGetters } from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });
import bootstrapBundleMin from '@/assets/js/bootstrap.bundle.min';

export const walletRequest = {
    data() {
        return {
            activeRequest: null,
            isRequestSuccessful: false,
            requestModalId: null,
            processingModalId: 'request-completion-modal',
            modalInfoId: 'business-request'
        }
    },
    computed: {
        ...mapGetters([
            "walletPolicies",
            "loaders"
        ]),
        requestTitle() {
            if (!this.activeRequest) return "N/A"
            return this.activeRequest.type.split("_").join(" ")
        },
    },
    mounted() {
        this.activeRequest = null
        this.requestModalId = null
    },
    methods: {
        ...mapActions([
          "saveWalletRequest"
        ]),
        handleRequestClick(type, modalId) {
            if (this.loaders.fetchWalletPolicies) {
                toaster.warning(`Please wait while we fetch this cluster's policies`)
                return false
            }

            this.isRequestSuccessful = false
            const request = this.walletPolicies.find( p => p.type = type)
            if (!request) {
              toaster.error(`No request of type ${type} found!`)
              return;
            }
            this.activeRequest = request
            this.requestModalId = modalId
      
            const businessRequestReviewModal = document.getElementById(this.modalInfoId);
            // const _businessRequestReviewModal = bootstrapBundleMin.Modal.getInstance(businessRequestReviewModal)
            const _businessRequestReviewModal = new bootstrapBundleMin.Modal(businessRequestReviewModal)
            _businessRequestReviewModal.show();
      
        },
        async handleContinueRequest() {
            const businessRequestReviewModal = document.getElementById(this.modalInfoId);
            const _businessRequestReviewModal = bootstrapBundleMin.Modal.getInstance(businessRequestReviewModal)
            _businessRequestReviewModal.hide();
      
            if (!this.requestModalId) {
              await this.requestBusinessRequest({})
              return;
            }
      
            const businessRequestModal = document.getElementById(this.requestModalId);
            const _businessRequestModal = new bootstrapBundleMin.Modal(businessRequestModal)
            _businessRequestModal.show();
        },
        async requestBusinessRequest(payload) {
            
            const businessRequestCompletionModal = document.getElementById(this.processingModalId);
            const _businessRequestCompletionModal = new bootstrapBundleMin.Modal(businessRequestCompletionModal)
            _businessRequestCompletionModal.show();
      
            const business_id = this.cluster.id;
            const {EVENT_TYPE, ...rest} = payload
            const data = {
              payload: JSON.stringify(rest), 
              business_id
            }
      
            if (payload.amount || payload.gross_amount) {
              data.amount = payload.amount || payload.gross_amount
            }
      
            if (this.activeRequest.id) {
              data.wallet_policy_id = this.activeRequest.id
            } else {
              data.type = EVENT_TYPE ? `${EVENT_TYPE}_REQUEST` : this.activeRequest.type
            }
      
            try {
              const res = await this.saveWalletRequest(data)
              this.isRequestSuccessful = true
            } catch (e) {
              console.log(e)
              toaster.error(`An error occored! `)
            }
        }
    }
}