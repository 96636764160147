<template>
    <modal :title="requestTitle" :id="id" title-icon="pi pi-chart-bar">
      <div class="text-left mb-4"> 
        <h6 class="capitalize">
          <i class="pi pi-clock"></i> {{ requestTitle || 'Pending Request' }} Information
        </h6>
      </div>
      <div class="text-left">
        <ul class="px-0">
          <li>
            <i class="pi pi-arrow-right text-muted" style="font-size: 10px;"></i>
            <span class="small">You are requesting for {{ getRequestName }}</span>
          </li>
          <li>
            <i class="pi pi-arrow-right text-muted" style="font-size: 10px;"></i>
            <span class="small">Kindly note that this will cost you <strong>NGN {{ request.admin_fee }}</strong> </span>
          </li>
          <li>
            <i class="pi pi-arrow-right text-muted" style="font-size: 10px;"></i>
            <span class="small">Note that this will require {{ request.member_vote }}{{ request.vote_unit === "PERCENTAGE" ? "%" : "" }} of cluster member to authorise</span>
          </li>
          <li v-if="request.requires_auth">
            <i class="pi pi-arrow-right text-muted" style="font-size: 10px;"></i>
            <span class="small">Note that this will require an admin member authorisation also</span>
          </li>
          <li>
            <i class="pi pi-arrow-right text-muted" style="font-size: 10px;"></i>
            <span class="small">This will take cluster member a total of {{ request.voting_period }} {{ request.voting_period_unit }} to complete authorisation</span>
          </li>
        </ul>
      </div>
      
      <hr/>

      <div class="">
        <app-button 
          @click="handleContinueClick" 
          title="Continue"
          icon="pi pi-check"
        ></app-button>
      </div>
    </modal>
</template>

<script>
    import Modal from '@/components/common/modal/Modal.vue';
    import AppButton from '@/components/common/form/AppButton.vue';

    export default {
      name: 'ModalBusinessRequest',
      props: ['id', 'request'],
      components: {
        Modal,
        AppButton
      },
      computed: {
        getRequestName() {
          if (!this.request.type) return 'n/a'
          return this.request.type.split("_").filter( n => n.toUpperCase() !== 'REQUEST').join(" ").toLowerCase()
        },
        requestTitle() {
          if (!this.request.type) return 'n/a'
          return this.request.type.split("_").join(" ")
        }
      },
      data() {
        return {
        }
      },
      methods: {
        handleContinueClick() {
          this.$emit('continue')
        },
      }
    }
</script>

<style scoped>
</style>
  