import AccountAction from "./account.action";
import LoaderAction from "./loader.action";
import NotificationAction from "./notification.action";
import TransactionAction from "./transaction.action";
import UserAction from "./user.action";
import QuestionAction from "./question.action";
import PlanAction from "./plan.action";
import SettingsAction from "./settings.action";
import BankAccountAction from "./bankAccount.action";
import WithdrawAction from "./withdraw.action";
import CoinsAction from "./coins.action";
import VasAction from "./vas.action";
import RateAction from "./rate.action ";
import BusinessAction from "./business.action"
import AppAction from "./app.action";
import SubscriptionAction from "./subscription.action";
import DisputeAction from "./dispute.action";
import CardAction from "./card.action";
import WalletAction from "./wallet.action";

const actions = {
    ...SettingsAction,
    ...TransactionAction,
    ...UserAction,
    ...PlanAction,
    ...LoaderAction,
    ...NotificationAction,
    ...AccountAction,
    ...QuestionAction,
    ...BankAccountAction,
    ...WithdrawAction,
    ...CoinsAction,
    ...VasAction,
    ...RateAction,
    ...BusinessAction,
    ...AppAction,
    ...SubscriptionAction,
    ...DisputeAction,
    ...CardAction,
    ...WalletAction
};

export default actions;