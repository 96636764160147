import http from "./http";


export const FetchBusinessById = async business => {
    return await http.get(`/business/${business}`);
}

export const FetchBusinessByCode = async code => {
    return await http.get(`/business/${code}/code`);
}

export const JoinBusiness = async data => {
    return await http.patch("/business/join", data);
};

export const CreateBusiness = async data => {
    return await http.post("/business", data);
};

export const FetchAppConfiguaration = async params => {
    return await http.get(`/app-configuration`, {params});
};

export const FetchBusinessAvailableState = async (params = {}) => {
    return await http.get(`/businesses/available-state`, {params});
};

export const FetchBusinessByState = async (params = {}) => {
    return await http.get(`/businesses/state`, {params});
};
