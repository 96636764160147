// import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import states from "./states";
import createPersistedState from "vuex-persistedstate";
// import app from "@/main";

// app.use(Vuex);

const store =  new Vuex.Store({
  state: states,
  mutations: mutations,
  actions: actions,
  modules: {},
  getters: getters,
  plugins: [createPersistedState({
    key: 'keyname',
    storage: window.localStorage,
    paths: [
      'auth',
      'cluster'
    ]
 })]
});

export default store;
