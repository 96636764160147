<template>

  <div class="modal fade bottom-align-modal" :id="id" aria-labelledby="modalTitle" role="dialog">
    <div class="modal-dialog modal-dialog-end">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" :id="`modalTitle-${ title ? title.split(' ').join('-') : Math.random()}`">
            <i :class="titleIcon" v-if="titleIcon"></i>
            {{ title }}
          </h6>
          <button class="btn btn-close p-1 ms-auto" type="button" id="close-modal-id" @click="$emit('close')" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Modal',
  props: ['title', 'id', 'titleIcon'],
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
