import { 
    JoinBusiness, 
    CreateBusiness,
    FetchBusinessById, 
    FetchBusinessByCode, 
    FetchAppConfiguaration, 
    FetchBusinessAvailableState, 
    FetchBusinessByState
} from "@/services/business";

export const joinBusiness = (context, data) => {
    context.commit("updateLoader", { joinBusiness: true });
    return new Promise((resolve, reject) => {
        JoinBusiness(data)
            .then(res => {
                const result = res.data.data;
                context.commit("joinBusiness", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { joinBusiness: false });
            });
    });
};

export const createBusiness = (context, data) => {
    context.commit("updateLoader", { createBusiness: true });
    return new Promise((resolve, reject) => {
        CreateBusiness(data)
            .then(res => {
                const result = res.data.data;
                context.commit("createBusiness", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { createBusiness: false });
            });
    });
};

export const fetchBusinessById = (context, data) => {
    context.commit("updateLoader", { fetchBusinessById: true });
    return new Promise((resolve, reject) => {
        FetchBusinessById(data)
            .then(res => {
                const result = res.data.data;
                context.commit("fetchBusinessById", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchBusinessById: false });
            });
    });
};

export const fetchBusinessByCode = (context, data) => {
    context.commit("updateLoader", { fetchBusinessByCode: true });
    return new Promise((resolve, reject) => {
        FetchBusinessByCode(data)
            .then(res => {
                const result = res.data.data;
                // context.commit("fetchBusinessByCode", result);
                resolve(result);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchBusinessByCode: false });
            });
    });
};

export const fetchAppConfig = (context, data) => {
    context.commit("updateLoader", { fetchAppConfig: true });
    return new Promise((resolve, reject) => {
        FetchAppConfiguaration(data)
            .then(res => {
                const data = res.data.data
                context.commit("appConfig", data);
                resolve(data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchAppConfig: false });
            });
    });
};

export const fetchBusinessAvailableState = (context, data) => {
    context.commit("updateLoader", { fetchBusinessAvailableState: true });
    return new Promise((resolve, reject) => {
        FetchBusinessAvailableState(data)
            .then(res => {
                const data = res.data.data
                context.commit("fetchBusinessAvailableState", data);
                resolve(data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchBusinessAvailableState: false });
            });
    });
};

export const fetchBusinessByState = (context, data) => {
    context.commit("updateLoader", { fetchBusinessByState: true });
    return new Promise((resolve, reject) => {
        FetchBusinessByState(data)
            .then(res => {
                const data = res.data.data
                context.commit("fetchBusinessByState", data);
                resolve(data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchBusinessByState: false });
            });
    });
};

const BusinessAction = {
    joinBusiness,
    createBusiness,
    fetchBusinessByCode,
    fetchAppConfig,
    fetchBusinessAvailableState,
    fetchBusinessByState,
    fetchBusinessById
};

export default BusinessAction;