<template>
  <page-template>
    <div class="page-content-wrapper py-3">
      

      <div class="container">
        <div class="card direction-rtl no-background">
          <div class="card-body">
            <!-- <h6 class="text-center"> Choose an option from our plan list </h6> -->
            <h6 class="text-center"><i class="pi pi-hashtag"></i> Select an Investment Plan </h6>
            <!-- <hr/> -->
            <!-- <div class="d-flex justify-content-center">
              <img src="../../../assets/images/plan/moon.png" class="img-plan"/>
              <h2 class="text-center text-green-700 bg-gray-700 d-flex align-items-center">
                {{ plan.base_currency || "" }} rate: {{ baseCurrency }} {{ planRate }}
              </h2>
            </div> -->
          </div>
        </div>
      </div>


      <div class="container">
        <div class="card no-background">
          <div class="card-body">
            <!-- Price Table One -->
            <div class="price-table-one">
              <ul class="nav nav-tabs border-bottom-0 mb-3 align-items-center justify-content-center" id="priceTab" role="tablist">
                <li class="nav-item" role="presentation"><a class="nav-link medium shadow" id="priceTab0" data-bs-toggle="tab" href="#priceTab_0" role="tab" aria-controls="priceTab_0" aria-selected="false"><i class="bi bi-egg"></i></a></li>
                <li class="nav-item" role="presentation"><a class="nav-link medium shadow" id="priceTab1" data-bs-toggle="tab" href="#priceTab_1" role="tab" aria-controls="priceTab_1" aria-selected="false"><i class="bi bi-lightning"></i></a></li>
                <li class="nav-item" role="presentation"><a class="nav-link medium shadow active" id="priceTab2" data-bs-toggle="tab" href="#priceTab_2" role="tab" aria-controls="priceTab_2" aria-selected="true"><i class="bi bi-cpu"></i></a></li>
              </ul>
              <div class="tab-content" id="priceTabContent">


                <div 
                  :class="`tab-pane fade ${i === (investmentPlans().length - 1) ? 'active show' :''}`" 
                  v-for="(plan, i) in investmentPlans()" 
                  :key="i" 
                  :id="`priceTab_${i}`" 
                  role="tabpanel" 
                  aria-labelledby="priceTabOne"
                  v-if="!loaders().getPlan"
                >
                  <!-- Single Price Table -->
                  <div class="single-price-content shadow-sm">
                    <div class="price"><span class="text-white mb-2">{{ plan.name }}</span>
                      <h2 class="display-3">{{ plan.name }}</h2>
                      <span class="badge bg-light text-dark rounded-pill mx-1" v-for="(t, j) in JSON.parse(plan.risk_type)" :key="i + '' + j">{{ t }}</span>
                    </div>
                    <!-- Pricing Desc -->
                    <div class="pricing-desc">
                      <ul class="ps-0">
                        <!-- <li><i class="bi bi-check-lg me-2"></i>{{  funding_source }} {{ plan.min_amount * planRate }} - {{  funding_source }} {{ plan.max_amount * planRate }}</li> -->
                        <li><i class="bi bi-check-lg me-2"></i>{{ auth() ? funding_source : plan.base_currency }} {{ rates() ? plan.min_amount * (activeRate.buy_rate / rates()[plan.base_currency].buy_rate) : plan.min_amount }} - {{ auth() ? funding_source : plan.base_currency }} {{ rates() ? plan.max_amount * (activeRate.buy_rate / rates()[plan.base_currency].buy_rate) : plan.max_amount }}</li>
                        <li><i class="bi bi-check-lg me-2"></i>{{ plan.duration }} Months Investment. </li>
                        <!-- <li><i class="bi bi-check-lg me-2"></i>{{ plan.how_to_buy }}</li> -->
                        <template v-if="plan.coin_types && plan.coin_types.length > 0">
                          <li v-for="(coin, k) in plan.coin_types" :key="k"><i class="bi bi-check-lg me-2"></i>You will get {{ (coin && coin.pivot) ? coin.pivot.quantity : 0 }} % {{ coin ? getCoinName(coin.name) : '' }} </li>
                        </template>
                        <!-- <li><i class="bi bi-check-lg me-2"></i>{{ plan.description }}</li> -->
                        <li v-for="(d, i) in (plan.description || '').split('-')" :key="i"><i class="bi bi-check-lg me-2"></i>{{ d }}</li>
                      </ul>
                    </div>
                    <!-- Purchase -->
                    <div class="purchase">
                      <button class="btn btn-warning btn-lg btn-creative w-100" data-bs-toggle="modal" data-bs-target="#purchaseModal" @click="selectPlan(plan)">Choose Plan</button>
                      <small class="d-block text-white mt-2 ms-1">No credit card required*</small>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="d-flex flex-column justify-content-center text-center">
                    <div class="my-4 text-center">
                      <i>Please wait while we load the investment plans</i>
                    </div>
                    <br/>
                    <div class="mb-4 text-center">
                      <div class="spinner-border" role="status">
                        <span class="sr-only"></span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="price-table-one mb-4">
        <ul class="nav nav-tabs border-bottom-0 mb-3 align-items-center justify-content-center mx-4">
          <span class="nav-item"></span>
          <span class="nav-item"></span>
          <li class="nav-item" role="presentation">
            <a class="nav-link small shadow" data-bs-toggle="tab" href="#" @click="() => $router.go(-1)" role="tab" aria-controls="priceTab_Two" aria-selected="false">
              <i class="pi pi-arrow-left"></i>
            </a>
          </li>
          <li class="nav-item" role="presentation">back</li>
        </ul>
      </div>


      <modal title="Confirm Plan Purchase" id="purchaseModal">
        <div v-if="!confirmReciept">
          <div class="text-center"> 
            <h6>
              Confirm {{ plan.name }} Plan Subsciption
            </h6>
            <h2>
              {{ plan.duration }} {{ plan.duration_unit + ( plan.duration === 1 ? "": "s") }}
            </h2>
          </div>

          <div class="form-group">
            <label class="form-label" for="amount-number">Amount</label>
            <input class="form-control form-control-clicked" id="amount-number" v-model="v$.amount.$model"  type="number" placeholder="">
            <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.amount.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>

          <div class="form-check">
            <input class="form-check-input" id="confirm" type="checkbox" v-model="v$.confirm.$model">
            <label class="form-check-label" for="confirm">confirm action</label>
            <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.confirm.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>

          <div class="text-center my-1">
            <!-- <app-button @click="subscribeToPlan" :title="'Confirm'" :loading="loaders().subscribe" :disabled="loaders().subscribe || v$.amount.$invalid || v$.confirm.$model === false" ></app-button> -->
            <app-button @click="validateSub" :title="'proceed'" :loading="loaders().subscribe" :disabled="loaders().subscribe || v$.amount.$invalid || v$.confirm.$model === false" ></app-button>
          </div>
        </div>

        <div class="card invoice-card shadow" v-else>
          <div class="card-body">
            <!-- Download Invoice -->
            <div class="download-invoice text-end mb-3">
              <a class="btn btn-sm btn-primary me-2" href="#" @click="printDiv('invoice-div')">
                <i class="bi bi-file-earmark-pdf"></i> PDF
              </a>
              <a class="btn btn-sm btn-light" href="#" @click="printDiv('invoice-div')">
                <i class="bi bi-printer"></i> Print
              </a>
            </div>

            <div id="invoice-div">
              <!-- Invoice Info -->
              <div class="invoice-info text-end mb-4">
                <h5 class="mb-1 fz-14">{{ plan.name }} | {{ name }}</h5>
                <h6 class="fz-12">{{ plan.duration }} {{ plan.duration_unit }}</h6>
                <p class="mb-0 fz-12">Date: {{ moment(new Date).format("DD MMM, YYYY") }} </p>
              </div>

              <!-- Invoice Table -->
              <div class="invoice-table">
                <div class="table-responsive">
                  <table class="table table-bordered caption-top">
                    <caption>Payment plan</caption>
                    <thead class="table-light">
                      <tr>
                        <th>Sl.</th>
                        <th>Description</th>
                        <th>Unit</th>
                        <th>Q.</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(save, i) in plan.duration" :key="i">
                        <td>{{ i + 1 }}</td>
                        <td>subscription for {{ plan.name }} plan</td>
                        <td>{{ plan.duration }} {{ plan.duration_unit }}</td>
                        <td>1</td>
                        <td>{{ funding_source }} {{ amount }}</td>
                      </tr>
                    </tbody>
                    <tfoot class="table-light">
                      <tr>
                        <td class="text-end" colspan="4">Total:</td>
                        <td class="text-end">{{ funding_source }} {{ amount }}</td>
                      </tr>
                      <tr>
                        <td class="text-end" colspan="4">Fee (0%):</td>
                        <td class="text-end">{{ funding_source }} 0</td>
                      </tr>
                      <tr>
                        <td class="text-end" colspan="4">Grand Total:</td>
                        <td class="text-end">{{ funding_source }} {{ amount }}</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>

            <div class="text-center my-1">
              <app-button @click="subscribeToPlan" :title="'Confirm'" :loading="loaders().subscribe" :disabled="loaders().subscribe || v$.amount.$invalid || v$.confirm.$model === false" ></app-button>
            </div>

            <p class="mb-0">Notice: This is auto generated invoice.</p>
          </div>
        </div>
      </modal>
      
    </div>
  </page-template>
</template>

<script>
// @ is an alias to /src
import PageTemplate from '@/components/templates/PageTemplate.vue'
import Modal from '@/components/common/modal/Modal.vue'
import { mapActions, mapGetters } from 'vuex'
import useVuelidate from '@vuelidate/core';
import { required, numeric, minLength } from '@vuelidate/validators';
import { createToaster } from "@meforma/vue-toaster";
import AppButton from '@/components/common/form/AppButton.vue';
import moment from 'moment'
import { printDiv, PLAN_TYPES } from '@/utils';
const toaster = createToaster({ /* options */ });

export default {
  name: 'PlanList',
  components: {
    PageTemplate,
    Modal,
    AppButton
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      planRate: 1,
      confirmReciept: false,
      plan: {},
      amount: null,
      confirm: false,
      baseCurrency: this.auth() ? this.auth().settings.base_currency : "NGN",
      funding_source: (Object.values(this.auth()).length > 0 && this.auth().settings) ? this.auth().settings.base_currency : "NGN",
      activeRate: this.rates() ? this.rates()["NGN"] || {} : {}
    }
  },
  validations() {
    return {
      amount: { required, numeric, min: minLength(1) },
      confirm: { required }
    }
  },
  mounted() {
    this.getAllPlans();
    if ( Object.values(this.rates()).length === 0 ) {
      const self = this;
      this.getRates().then( res => self.setRate())
    }
    if ( Object.values(this.account()).length === 0 ) {
      this.myAccount()
    }
    this.setRate()
  },
  created() {
    this.moment = moment;
    this.printDiv = printDiv;
  },
  methods: {
    ...mapGetters([
      "investmentPlans",
      "loaders",
      "auth",
      "account",
      "rates",
      "cluster"
    ]),
    ...mapActions([
      "getAllPlans",
      "subscribePlan",
      "myAccount",
      "getRates"
    ]),
    getCoinName(n) {
      const abbr = {
        LC: "Large Cap Coin",
        MC: "Medium Cap Coin",
        SM: "Small Cap Coin",
        LCC: "Large Cap Coin",
        MCC: "Medium Cap Coin",
        SMC: "Small Cap Coin"
      }
      const keys =  Object.keys(abbr);
      return ( keys.includes(n) ) ? abbr[n] : n; 
    },
    setRate() {
      if (Object.values(this.rates()).length === 0 || Object.values(this.auth()).length === 0) {
        return
      }
      this.activeRate = this.rates()[this.auth().settings.base_currency || "NGN"]
      this.planRate = this.rates()[this.baseCurrency].buy_rate / this.rates()[this.plan.base_currency || "USDT"].buy_rate
    },
    selectPlan( plan ) {
      this.confirmReciept = false
      this.plan = plan;
      this.setRate()
      this.amount = ( this.planRate * plan.max_amount ) || null;
    },
    validateSub() {
      // const minAmt = this.plan.min_amount * this.planRate;

      const minAmt = this.rates() && this.activeRate ? this.plan.min_amount * (this.activeRate.buy_rate / this.rates()[this.plan.base_currency].buy_rate) : this.plan.min_amount;

      if ( this.amount < minAmt ) {
        toaster.error("because of your selected plan, use an amount greater than " + this.funding_source + " " + minAmt );
        return
      }

      if ( this.account().wallet.NGN.ledger_balance < this.amount ) {
        toaster.show("Your balance is low. " + this.account().wallet.NGN.base_currency + " " + this.account().wallet.NGN.ledger_balance.toFixed(3) );
        return
      }

      this.confirmReciept = true
    },
    subscribeToPlan() {
      const userUuid = window.localStorage.getItem("uuid");
      const data = {
        user_uuid: userUuid,
        plan_id: this.plan.id,
        amount: this.amount,
        type: this.plan.type || PLAN_TYPES.crypto,
        funding_source: this.funding_source
      }
      if (this.cluster() && this.cluster().id) {
        data.business_id = this.cluster().id
      }
      const self = this;
      this.subscribePlan(data).then( res => {
        console.log(res);
        self.$router.push({
          path: "/subscribe-success"
        });
      });
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.price-table-one .single-price-content{
  background-color: #061238;
}
.price-table-one .nav-tabs .nav-link.active  {
  background-color: #061238;
    color: #ffffff;
    border-color: #061238;
}
.price-table-one .nav-tabs .nav-link {
  color: #061238;
}

.img-plan {
  width: 80px;
}
.price-table-one .tab-content .tab-pane:nth-child(2) .single-price-content {
    background-color: var(--jw-text-success);
}
.price-table-one .nav-tabs .nav-item:nth-child(2) .nav-link.active {
    background-color: var(--jw-text-success);
    border-color: var(--jw-text-success);
    color: #ffffff;
}
.price-table-one .nav-tabs .nav-item:nth-child(2) .nav-link {
    color: var(--jw-text-success);
}
</style>
