export const transactionPinStatus = (s, v) => s.transactionPinStatus = v
export const fetchWalletPolicies = (s, v) => s.walletPolicies = v
export const fetchWalletRequests = (s, v) => s.walletRequests = v
export const saveWalletRequest = (s, v) => s.walletRequests.data = [v,...s.walletRequests.data]
export const updateWalletRequest = (state, walletRequest) => {
    const walletRequestIndex = state.walletRequests.data.findIndex(
        (_walletRequest) => _walletRequest.id === walletRequest.id
    );
    if (walletRequestIndex < 0) {
        return;
    }
    state.walletRequests.data[walletRequestIndex] = walletRequest;
};

const WalletMutation = {
    transactionPinStatus,
    fetchWalletPolicies,
    fetchWalletRequests,
    saveWalletRequest,
    updateWalletRequest
};

export default WalletMutation;