import http from "./http";

export const Withdraw = async data => {
    return await http.post("/withdraw", data);
};

export const Sell = async data => {
    return await http.post("/sell", data);
};

export const Send = async data => {
    return await http.post("/send", data);
};

export const FetchWithdraw = async () => {
    return await http.get("/withdraw");
};

export const FetchSell = async () => {
    return await http.get("/sell");
};

export const FetchSend = async () => {
    return await http.get("/send");
};