const AccountGetter = {
    activeAccount: state => {
        return state.activeAccount || window.localStorage.getItem("activeAccount")
    },
    visibility: state => {
        return state.visible || window.localStorage.getItem("visible")
    },
    accountDetails: (state) => state.accountDetails,
    groups: (state) => state.groups,
    account: (state) => state.account || {wallet: {}, plan: {}},
    settings: (state) => state.settings,
    profit: state => state.profit,
    accountBalance: state => state.accountBalance,
    pendingAddressVerification: _ => {
        const address_confirmation_date = window.localStorage.getItem("address_confirmation_date");
        // console.log(address_confirmation_date)
        if (!address_confirmation_date) {
            return false;
        }
        const now = new Date();
        const then = new Date(address_confirmation_date);
        return then.getTime() >= now.getTime()
    } 
};

export default AccountGetter;