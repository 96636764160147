import {
    WalletInquiry, 
    WalletTransfer, 
    ChangePin, 
    TransactionPinStatus, 
    ConfirmPin,
    FetchWalletPolicies,
    FetchWalletRequests, 
    GetWalletRequest, 
    SaveWalletRequest,
    UpdateWalletRequest,
    VoteWalletRequest,
    // DeleteWalletRequest
} from "@/services/wallet";

export const walletInquiry = (context, data) => {
    context.commit("updateLoader", { walletInquiry: true });
    return new Promise((resolve, reject) => {
        WalletInquiry(data)
            .then(res => {
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { walletInquiry: false });
            });
    });
};

export const walletTransfer = (context, data) => {
    context.commit("updateLoader", { walletTransfer: true });
    return new Promise((resolve, reject) => {
        WalletTransfer(data)
            .then(res => {
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { walletTransfer: false });
            });
    });
};

export const changePin = (context, data) => {
    context.commit("updateLoader", { changePin: true });
    return new Promise((resolve, reject) => {
        ChangePin(data)
            .then(res => {
                // context.commit("changePin", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { changePin: false });
            });
    });
};

export const confirmPin = (context, data) => {
    context.commit("updateLoader", { confirmPin: true });
    return new Promise((resolve, reject) => {
        ConfirmPin(data)
            .then(res => {
                // context.commit("confirmPin", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { confirmPin: false });
            });
    });
};

export const transactionPinStatus = (context, data) => {
    context.commit("updateLoader", { transactionPinStatus: true });
    return new Promise((resolve, reject) => {
        TransactionPinStatus(data)
            .then(res => {
                context.commit("transactionPinStatus", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { transactionPinStatus: false });
            });
    });
};

export const fetchWalletPolicies = (context, data) => {
    context.commit("updateLoader", { fetchWalletPolicies: true });
    return new Promise((resolve, reject) => {
        FetchWalletPolicies(data)
            .then(res => {
                context.commit("fetchWalletPolicies", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchWalletPolicies: false });
            });
    });
}

export const fetchWalletRequests = (context, data) => {
    context.commit("updateLoader", { fetchWalletRequests: true });
    return new Promise((resolve, reject) => {
        FetchWalletRequests(data)
            .then(res => {
                context.commit("fetchWalletRequests", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchWalletRequests: false });
            });
    });
}

export const getWalletRequest = (context, data) => {
    context.commit("updateLoader", { getWalletRequest: true });
    return new Promise((resolve, reject) => {
        GetWalletRequest(data)
            .then(res => {
                context.commit("updateWalletRequest", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getWalletRequest: false });
            });
    });
}

export const saveWalletRequest = (context, data) => {
    context.commit("updateLoader", { saveWalletRequest: true });
    return new Promise((resolve, reject) => {
        SaveWalletRequest(data)
            .then(res => {
                context.commit("saveWalletRequest", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { saveWalletRequest: false });
            });
    });
}

export const voteWalletRequest = (context, data) => {
    context.commit("updateLoader", { voteWalletRequest: true });
    const {id, ...payload} = data
    return new Promise((resolve, reject) => {
        VoteWalletRequest(id, payload)
            .then(res => {
                context.commit("updateWalletRequest", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { voteWalletRequest: false });
            });
    });
}

export const updateWalletRequest = (context, data) => {
    context.commit("updateLoader", { updateWalletRequest: true });
    return new Promise((resolve, reject) => {
        UpdateWalletRequest(data)
            .then(res => {
                context.commit("updateWalletRequest", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { updateWalletRequest: false });
            });
    });
}

const WalletAction = {
    walletInquiry,
    walletTransfer,
    changePin,
    confirmPin,
    transactionPinStatus,
    fetchWalletPolicies,
    fetchWalletRequests,
    getWalletRequest,
    saveWalletRequest,
    updateWalletRequest,
    voteWalletRequest
};

export default WalletAction;