import http from "./http";

export const FetchWalletPolicies = async params => {
    return await http.get("/wallet-policies", {params});
}

export const FetchWalletRequests = async params => {
    return await http.get("/wallet-requests", {params});
}

export const GetWalletRequest = async (id) => {
    return await http.get(`/wallet-request/${id}`);
}

export const SaveWalletRequest = async data => {
    return await http.post("/wallet-request", data);
}

export const UpdateWalletRequest = async(id, data) => {
    return await http.put(`/wallet-request/${id}`, data);
}

export const VoteWalletRequest = async(id, data) => {
    return await http.patch(`/wallet-requests/${id}/vote`, data);
}

export const DeleteWalletRequest = async id => {
    return await http.delete(`/wallet-request/${id}`);
}


export const WalletInquiry = async data => {
    return await http.post("/wallet/inquire", data)
}

export const WalletTransfer = async data => {
    return await http.post("/wallet/transfer", data)
}

export const ChangePin = async data => {
    return await http.put("/wallet/pin-update", data)
}

export const ConfirmPin = async data => {
    return await http.post("/wallet/pin-confirm", data)
}

export const TransactionPinStatus = async () => {
    return await http.get("/wallet/pin-status")
}