<template>
    <modal 
        :title="`Transfer ${!transferType ? '' : ' to ' + transferType}`" 
        :id="id" 
        title-icon="pi pi-arrow-right-arrow-left" 
        @close="fiatModalClose"
    >
      <div>
        <div v-if="!transferType">
          <div class="text-left"> 
            <h6><i class="pi pi-building-columns"></i> Send money to</h6>
          </div>
          <div class="text-left mt-2">
            <p>Specify where to transfer to!</p>
            <ul class="px-0">
                <li>
                    <i class="pi pi-arrow-right text-muted" style="font-size: 10px;"></i>
                    <button class="btn m-1 btn-sm btn-warning shadow" @click="selectType('Bank Account')">
                        <i class="pi pi-building-columns" style="font-size: 11px;"></i> Bank Account
                    </button>
                    <span class="small"> any bank account</span> <i class="pi pi-sun" style="font-size: 10px;"></i>
                </li>
                <li>
                    <i class="pi pi-arrow-right text-muted" style="font-size: 10px;"></i>
                    <button class="btn m-1 btn-sm btn-warning shadow" @click="selectType('Redge Account')">
                        <i class="pi pi-wallet" style="font-size: 11px;"></i> Redge Wallet &nbsp;
                    </button>
                    <span class="small"> wallet account</span> <i class="pi pi-bolt" style="font-size: 10px;"></i>
                </li>
            </ul>
          </div>
        </div>

        <div v-else>

          <div v-if="stage === stages.FIAT_TRANSFER_BANK">
            <div class="text-left"> 
              <h6><i class="pi pi-building-columns"></i> Bank Account Details</h6>
            </div>
            <div v-if="useBeneficiary">
                <div class="form-group position-relative">
                    <input class="form-control form-control-clicked mb-2 px-5" id="searchBankAccounts" type="text" @keyup="searchBankAccounts" placeholder="Search...">
                    <div class="position-absolute form-control-prepend">
                        <i class="pi pi-search"></i>
                    </div>
                </div>
                <div class="mt-2" v-if="bankAccountList.length > 0">
                    <div class="overflow-auto" style="height: 470px;">
                    <div 
                        class="single-plan-check shadow-sm"
                        :class="{'active-effect active': bank && bank.uuid === _bank.uuid}" 
                        v-for="(_bank, i) in bankAccountList" 
                        :key="i"
                    >
                        <div class="form-check mb-0" @click="selectBank(_bank)">
                        <input class="form-check-input" type="radio" name="bank-account" :id="`account${i+1}`">
                        <label class="form-check-label" :for="`account${i+1}`">{{ _bank.bank_name }} | {{ _bank.account_number }} | {{ _bank.account_name }}</label>
                        </div>
                    </div>
                    </div>
                    <hr/>
                    <div class="text-center">Or</div>
                    <div class="d-flex justify-content-between mt-2">
                    <router-link class="btn btn-sm btn-warning shadow" to="/bank-account" >
                        <i class="pi pi-building-columns"></i> Add New Bank Account
                    </router-link>
                    <button class="btn btn-sm btn-warning shadow"  @click="selectBankDetail">
                        <i class="pi pi-pen-to-square"></i> Enter Details
                    </button>
                    </div>
                </div>
                <div class="d-flex flex-column justify-content-center text-center" v-else-if="loaders.fetchBankAccount">
                    <div class="my-4 text-center">loading bank account details...</div>
                    <br/>
                    <div class="mb-4 text-center">
                    <div class="spinner-border" role="status">
                        <span class="sr-only"></span>
                    </div>
                    </div>
                </div>
                <div class="text-center mt-2" v-else>
                    <p class="my-4"> <i class="pi pi-exclamation-circle"></i> No bank account yet!</p>
                    <div class="d-flex justify-content-between mt-2">
                    <router-link class="btn btn-sm btn-warning shadow" to="/bank-account" >
                        <i class="pi pi-building-columns"></i> Add Bank Account
                    </router-link>
                    <button class="btn btn-sm btn-warning shadow"  @click="selectBankDetail">
                        <i class="pi pi-pen-to-square"></i> Enter Details
                    </button>
                    </div>
                </div>
            </div>
            <div v-else>
              <form>
                <div class="form-group mb-3">
                    <label class="form-label" for="bank"><i class="pi pi-building-columns"></i> Bank</label>
                    <v-select 
                        :options="banks" 
                        label="name" 
                        v-model="v$.bankAccount.bank.$model" 
                        @option.selected="() => validateAccount(null)" 
                        @input="() => validateAccount(null)" 
                    ></v-select>
                </div>

                <div class="form-group mb-3">
                  <label class="form-label" for="account-number"><i class="pi pi-building-columns"></i> Account Number</label>
                  <input class="form-control" type="tel" pattern="[0-9]*" :disabled="loaders.enquire" id="account-number" v-model="v$.bankAccount.account_number.$model" maxlength="10" placeholder="00012..." @keyup="validateAccount" @change="validateAccount">
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.bankAccount.account_number.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>

                <div class="form-group mb-3">
                  <label class="form-label" for="account-number"><i class="pi pi-user"></i> Account Name</label>
                  <input class="form-control" id="account-number" v-model="v$.bankAccount.account_name.$model" type="text" disabled>
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.bankAccount.account_name.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="form-check">
                  <input class="form-check-input" id="save_beneficiary" type="checkbox" v-model="v$.bankAccount.save_beneficiary.$model">
                  <label class="form-check-label" for="save_beneficiary">Save Beneficiary</label>
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.bankAccount.save_beneficiary.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>

                <app-button @click="handleContinueBankWithdraw" :title="'Continue'" :loading="loaders.enquire" :disabled="loaders.enquire || v$.bankAccount.$invalid" ></app-button>
              </form>
              <hr/>
              <div class="text-center">Or</div>
              <div class="d-flex justify-content-between mt-2">
                <button class="btn btn-sm btn-warning shadow"  @click="selectBeneficiary">
                    <i class="pi pi-user"></i> Select Beneficiary
                </button>
                <router-link class="btn btn-sm btn-warning shadow" to="/bank-account" >
                    <i class="pi pi-building-columns"></i> Add Bank Account
                </router-link>
              </div>
            </div>
          </div>

          <div v-else-if="stage === stages.FIAT_TRANSFER_WALLET">
                <form>
                    <div class="form-group">
                        <label class="form-label" for="wallet-number"><i class="pi pi-user"></i> Wallet Number</label>
                        <input type="tel" pattern="[0-9]*" class="form-control form-control-clicked" id="wallet-number" placeholder="0796..." v-model="v$.walletNumber.$model" >
                        <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.walletNumber.$errors" :key="index">
                        <div class="error-msg">{{ error.$message }}</div>
                        </div>
                    </div>
                    
                    <app-button 
                        @click="fetchUserWallet" 
                        classes="my-1 btn-warning w-100 shadow" 
                        title="next" 
                        :loading="loaders.withdraw || loaders.walletInquiry" 
                        :disabled="loaders.withdraw || loaders.walletInquiry || v$.walletNumber.$invalid" 
                    ></app-button>
                </form>
                <hr/>
                <span @click="fiatModalClose" class="cursor-pointer">
                    <i class="pi pi-arrow-left text-muted" style="font-size: 11px;"></i> Back
                </span>
          </div>

          <div v-else-if="stage === stages.FIAT_TRANSFER_FORM">
            <div class="text-left"> 
              <h6>Let's finalize this transaction</h6>
            </div>
            <div class="">

              <div class="alert custom-alert-3 alert-secondary alert-dismissible fade show" role="alert" style="padding-right: 10px;"><i class="bi bi-check-all"></i>
                <div class="alert-text">
                  <h6><i class="pi pi-building-columns"></i> Recepient Account</h6>
                  <span>{{ bank.bank_name }} | {{ bank.account_number }} | {{ bank.account_name }}</span>
                </div>
                <button class="btn btn-close position-relative p-1 ms-auto" type="button" @click="clearSelectedBank"></button>
              </div>

              <form>
                <div>
                  <label class="form-label" for="amount">Amount</label>
                  <div class="input-group mb-3">
                    <div class="input-group-text">
                      <span>{{ funding_source.base_currency  }}</span>
                    </div>
                    <input class="form-control form-control-clicked" id="amount" type="number" placeholder="20000" v-model="v$.withdraw.amount.$model" aria-label="Text input with checkbox">
                  </div>
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.withdraw.amount.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>

                <div class="form-group">
                  <label class="form-label" for="description">Description <small><i>(optional)</i></small></label>
                  <input class="form-control form-control-clicked" id="description" type="text" placeholder="give away." v-model="v$.withdraw.description.$model" >
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.withdraw.description.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>

                <!-- <div>
                  <label class="form-label" for="data-network">Spend from</label>
                  <div class="input-group mb-3">
                    <div class="input-group-text select-input">
                      <select class="form-select" :disabled="loaders.fetchAccount" id="transfer-wallet-fiat" name="transfer-wallet-fiat" aria-label="transfer-wallet-fiat" v-model="v$.funding_source.$model">
                        <option v-for="(w, i) in Object.values(wallet).filter(w => w.base_currency !== 'USDT' && w.base_currency !== 'USD' && w.base_currency)" :key="i" :selected="funding_source.base_currency === w.base_currency" :value="w">{{ w.base_currency }} wallet</option>
                      </select>
                    </div>
                    <input class="form-control" disabled type="text" :value="funding_source.ledger_balance.toFixed(2)" aria-label="Text input with checkbox" placeholder="0.00">
                  </div>
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.funding_source.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div> -->
                
                <div>
                  <p>Transaction fee {{ formatter(recepientFiatCurrency).format(transferRate * transferFee) }} </p>
                </div>

                <div class="form-check">
                  <input class="form-check-input" id="confirm" type="checkbox" v-model="v$.withdraw.confirm.$model">
                  <label class="form-check-label" for="confirm">confirm action</label>
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.withdraw.confirm.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
                <!-- <app-button @click="transferFiat" :classes="'my-1 btn-warning w-100 shadow'" :title="'withdraw'" :loading="loaders.withdraw" :disabled="loaders.withdraw || v$.withdraw.$invalid" ></app-button> -->
                <app-button 
                    @click="validateFiatForm" 
                    classes="my-1 btn-warning w-100 shadow" 
                    title="confirm" 
                    :loading="loaders.withdraw" 
                    :disabled="loaders.withdraw || v$.withdraw.$invalid"
                ></app-button>
              </form>
            </div>
          </div>

          <div class="card invoice-card shadow" v-else-if="stage === stages.FIAT_TRANSFER_RECEIPT">
            <div class="card-body">
              <!-- Download Invoice -->
              <div class="download-invoice text-end mb-3">
                <a class="btn btn-sm btn-primary me-2" hid="#" @click="printDiv('invoice-div')">
                  <i class="bi bi-file-earmark-pdf"></i> PDF
                </a>
                <a class="btn btn-sm btn-light" hid="#" @click="printDiv('invoice-div')">
                  <i class="bi bi-printer"></i> Print
                </a>
              </div>

              <div id="invoice-div">
                <!-- Invoice Info -->
                <div class="invoice-info text-end mb-4">
                  <h5 class="mb-1 fz-14">Funds transfer</h5>
                  <h6 class="fz-12">Destination: {{ transferType }}</h6>
                  <p class="mb-0 fz-12">Date: {{ moment(new Date).format("DD MMM, YYYY") }} </p>
                </div>

                <!-- Invoice Table -->
                <div class="invoice-table">
                  <div class="table-responsive">
                    <table class="table table-bordered caption-top">
                      <caption>Summary</caption>
                      <thead class="table-light">
                        <tr>
                          <th>Sl.</th>
                          <th>Description</th>
                          <th>Deliver</th>
                          <th>Q.</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Transfer of {{ funding_source.base_currency || "NGN" }} {{ withdraw.amount }} to {{ bank.bank_name }} {{ bank.account_name }}</td>
                          <td> {{ recepientFiatCurrency }} {{ (withdraw.amount * transferRate) }}</td>
                          <td>1</td>
                          <td>{{ funding_source.base_currency || "NGN"}} {{ withdraw.amount }}</td>
                        </tr>
                        <tr v-if="extractCharges && extractCharges.amount">
                          <td>2</td>
                          <td>{{ extractCharges.description || "Charges" }}</td>
                          <td> {{ recepientFiatCurrency }} {{ (extractCharges.amount * transferRate) }}</td>
                          <td>1</td>
                          <td>{{ funding_source.base_currency || "NGN"}} {{ (extractCharges.amount * transferRate) }}</td>
                        </tr>
                      </tbody>
                      <tfoot class="table-light">
                        <tr>
                          <td class="text-end" colspan="4">Total:</td>
                          <td class="text-end">
                            {{ funding_source.base_currency || "NGN"}} 
                            {{ withdraw.amount + (extractCharges && extractCharges.amount ? (extractCharges.amount || 0) * transferRate : 0) }}</td>
                        </tr>
                        <tr>
                          <!-- <td class="text-end" colspan="4">Fee (1.4% + 50):</td>
                          <td class="text-end">{{ funding_source.base_currency || "NGN"}} {{ Math.round(((withdraw.amount * 0.014) + 50) * 10) / 10 }}</td> -->
                          <td class="text-end" colspan="4">Fee :</td>
                          <td class="text-end">{{ funding_source.base_currency || "NGN"}} {{ transferFee }}</td>
                        </tr>
                        <tr>
                          <td class="text-end" colspan="4">Grand Total:</td>
                          <td class="text-end">
                            {{ funding_source.base_currency || "NGN"}} 
                            {{ withdraw.amount + ( transferFee / transferRate ) + + (extractCharges && extractCharges.amount ? (extractCharges.amount || 0) * transferRate : 0) }}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>

              <div class="text-center my-1">
                <app-button 
                    @click="stage=stages.FIAT_TRANSFER_AUTHORIZE" 
                    classes="my-1 btn-warning w-100 shadow" 
                    title="transfer" 
                    :loading="loaders.withdraw || loaders.walletTransfer" 
                    :disabled="loaders.withdraw || loaders.walletTransfer || v$.withdraw.$invalid" 
                ></app-button>
              </div>

              <p class="mb-0">Notice: This is auto generated invoice.</p>
            </div>
          </div>

            <verify-transaction-pin 
                v-if="stage === stages.FIAT_TRANSFER_AUTHORIZE" 
                :currency="funding_source.base_currency" 
                @verified="handleTransferAuthVerification"
            ></verify-transaction-pin>

        </div>
      </div>
    </modal>
</template>

<script>
    import Modal from '@/components/common/modal/Modal.vue'
    import useVuelidate from '@vuelidate/core';
    import { createToaster } from "@meforma/vue-toaster";
    import { mapGetters, mapActions } from 'vuex';
    import { required, minLength } from '@vuelidate/validators';
    import VerifyTransactionPin from '@/components/common/wallet/VerifyTransactionPin.vue';
    import SuccessNotification from '@/components/common/notification/SuccessNotification.vue';
    import bootstrapBundleMin from '@/assets/js/bootstrap.bundle.min';
    import AppButton from '@/components/common/form/AppButton.vue';
    const toaster = createToaster({ /* options */ });
    import { formatter, printDiv } from '@/utils';
    import moment from 'moment'
    import { strings, bankAccount } from '@/mixins';

    export default {
        name: 'ModalWithdrawal',
        props: [
          'id', 
          'source', 
          'extractCharges',
          'native'
        ],
        mixins: [ strings, bankAccount ],
        components: {
            Modal,
            AppButton,
            SuccessNotification,
            VerifyTransactionPin
        },
        setup () {
            return { v$: useVuelidate() }
        },
        validations() {
            return {
                funding_source: { required },
                bankAccount: {
                    account_number: { required },
                    bank: { required },
                    account_name: { required },
                    save_beneficiary: {},
                    description: {}
                },
                walletNumber: { required },
                withdraw: {
                    confirm: { required },
                    amount: { required, min: minLength(1) },
                    description: {}
                },
            }
        },
        async mounted() {
          this.funding_source = this.source || {
              base_currency: this.recepientFiatCurrency
          }
          this.getBanks();
          this.getRates();
          this.formatter = formatter
          this.printDiv = printDiv
          this.moment = moment
        },
        watch: {
          source: function(val) {
            this.funding_source = val
          }
        },
        data() {
            return {
                transferFee: 0,
                transferRate: 1,
                transferType: null,
                useBeneficiary: false,
                bankAccount: {},
                withdraw: {},
                funding_source: {},
                walletNumber: null,
                bank: null,
                prevStage: null,
                stage: null,
                stages: {
                    FIAT_TRANSFER_INIT: "fiat_transfer_init",
                    FIAT_TRANSFER_BANK: "fiat_transfer_bank",
                    FIAT_TRANSFER_WALLET: "fiat_transfer_wallet",
                    FIAT_TRANSFER_FORM: "fiat_transfer_form",
                    FIAT_TRANSFER_RECEIPT: "fiat_transfer_receipt",
                    FIAT_TRANSFER_AUTHORIZE: "fiat_transfer_authorize"
                },
                recepientFiatCurrency: "NGN",
            }
        },
        computed: {
            ...mapGetters([
                "loaders",
                "account",
                "banks",
                "rates",
            ]),
        },
        methods: {
            ...mapActions([
                "getBanks",
                "walletInquiry",
                "enquire",
                "getRates",
                "withdrawToBank",
                "walletTransfer"
            ]),
            fetchUserWallet() {
                this.walletInquiry({wallet: this.walletNumber})
                .then( res => {
                    this.bank = res
                    this.stage = this.stages.FIAT_TRANSFER_FORM
                    this.prevStage = this.stages.FIAT_TRANSFER_WALLET
                })
                .catch( err => {
                    const { message = "An error occured" } = err;
                    toaster.error(message);
                })
            },
            validateAccount(e = null) {
                const account_number = e !== null ? e.target.value : this.bankAccount.account_number;
                if ( !account_number || account_number.length !== 10 || this.loaders.enquire )
                    return false;
                // validate
                const data = {
                    bank_code: this.bankAccount.bank.code,
                    account_number
                }
                const self = this
                this.enquire(data).then( res => {
                    self.bankAccount = {
                        ...self.bankAccount,
                        account_name: res.account_name,
                        bank_name: res.bank
                    };
                }).catch()
            },
            handleContinueBankWithdraw() {
                this.bank = this.bankAccount;
                this.stage = this.stages.FIAT_TRANSFER_FORM
            },
            selectBeneficiary(){
                this.useBeneficiary = true;
                this.bank = null;
                this.bankAccount = {};
            },
            selectBankDetail(){
                this.useBeneficiary = false;
                this.bank = null;
                this.bankAccount = {}
            },
            selectBank(bank) {
                this.bank = bank;
                this.stage = this.stages.FIAT_TRANSFER_FORM
            },
            handleTransferAuthVerification(verified) {
                if (!verified) {
                    return
                }
                this.stage = this.stages.FIAT_TRANSFER_RECEIPT;
                this.transferFiat()
            },
            transferFiat() {
                if ( this.transferType === "Bank Account" ) {
                    return this.withdrawFunds()
                } else if ( this.transferType === "Redge Account") {
                    return this.transferToWallet()
                }
            },
            validateFiatForm() {
                const data = {
                    amount: this.withdraw.amount,
                    receive_wallet: this.bank.bank_name,
                    receiver: this.bank.account_number,
                    send_wallet: this.funding_source.base_currency
                };
                // console.log(this.rates, data)
                const rate = this.rates[data.send_wallet].sell_rate / this.rates[this.recepientFiatCurrency].sell_rate

                if ( data.amount < (200 * rate)) {
                    toaster.show("you cannot withdraw below " + data.send_wallet + " " + (200 * rate).toFixed(2));
                    return
                }
                if ( this.account.wallet[data.send_wallet].ledger_balance < data.amount + this.transferFee ) {
                    toaster.show("you cannot withdraw above your available balance of " + data.send_wallet + " " + this.account.wallet[data.send_wallet].ledger_balance.toFixed(2) );
                    return
                }

                this.confirmReciept = true
                this.stage = this.stages.FIAT_TRANSFER_RECEIPT
            },
            transferToWallet() {

                const data = {
                    amount: this.withdraw.amount,
                    receive_wallet: this.bank.bank_name,
                    receiver: this.bank.account_number,
                    send_wallet: this.funding_source.base_currency,
                    description: this.withdraw.description,
                };

                if (this.native) {
                  data.EVENT_TYPE = "TRANSFER"
                  return this.nativeResponse(data)
                }
                
                this.recepientFiatCurrency = data.receive_wallet;

                const self = this;
                this.walletTransfer(data).then( res => {
                    const withdrawModal = document.getElementById(this.id);
                    const _withdrawModal = bootstrapBundleMin.Modal.getInstance(withdrawModal)
                    _withdrawModal.hide();

                    self.$router.push({
                        path: '/bank-withdraw-success', 
                        query: { amount: data.amount, currency: data.send_wallet, bank: JSON.stringify(self.bank) }
                    });
                }).catch(err => {
                    const { message = "Something went wrong"} = err
                    toaster.warning(message)
                })

            },
            withdrawFunds() {
                if ( this.withdraw.amount < 200 ) {
                    toaster.show("you cannot withdraw below N200");
                    return
                }
                if ( this.account.wallet.NGN.ledger_balance < this.withdraw.amount ) {
                    toaster.show(`you cannot withdraw above your available balance of ${formatter(this.account.wallet.NGN.base_currency).format(this.account.wallet.NGN.ledger_balance)}`);
                    return
                }
                const userUuid = window.localStorage.getItem("uuid");
                var uniq = userUuid + '-' + (new Date()).getTime();
                let data = {
                    amount: this.withdraw.amount,
                    user_uuid: userUuid,
                    // bank_account_id: this.bank.id,
                    reference: uniq,
                    send_wallet: this.funding_source.base_currency,
                    description: this.withdraw.description,
                };

                if ( Object.values(this.bankAccount).some(v => v) ) {
                    data = {
                        ...data,
                        ...this.bankAccount
                    }
                    if ( data.bank ) {
                        data.bank_code = data.bank.code;
                        data.bank_name = data.bank.name;
                        delete data.bank
                    }
                } else if (typeof this.bank === "object") {
                    data.bank_account_id = this.bank.id;
                }

                if (this.native) {
                  data.EVENT_TYPE = "WITHDRAW"
                  return this.nativeResponse(data)
                }

                const self = this;
                this.withdrawToBank(data).then( res => {
                    const withdrawModal = document.getElementById(this.id);
                    const _withdrawModal = bootstrapBundleMin.Modal.getInstance(withdrawModal)
                    _withdrawModal.hide();

                    self.$router.push({
                        path: '/bank-withdraw-success', 
                        query: { amount: data.amount, currency: 'N', bank: JSON.stringify(self.bank) }
                    });
                }).catch(err => {
                    const { message = "Something went wrong"} = err
                    toaster.warning(message)
                })
            },
            nativeResponse(data) {
              const withdrawModal = document.getElementById(this.id);
              const _withdrawModal = bootstrapBundleMin.Modal.getInstance(withdrawModal)
              _withdrawModal.hide();
              this.$emit('payload', data)
              return true
            },
            fiatModalClose() {
                this.transferType = null;
                this.bank = {};
                this.walletNumber = null;
                this.confirmReciept = false
                this.stage = null
            },
            selectType(type) {
                this.transferType = type;
                this.transferFee = type === "Bank Account" ? 20 : 0
                this.stage = type === "Bank Account" ? this.stages.FIAT_TRANSFER_BANK : this.stages.FIAT_TRANSFER_WALLET
            },
            clearSelectedBank() {
                this.bank = {}
                this.bankAccount = {}
                this.stage = this.prevStage
            },
        }
    }
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}



.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

</style>
  