<template>
  <div class="container">
    <div class="card bg-card-primary bg-gradient rounded-lg">
      <div class="card-body">
        <div class="colorful-tab colorful-tab-card" >
          <div class="tab-content shadow-sm p-3" id="affanTab3Content">
            <div class="tab-pane fade text-left show active" id="fiat" role="tabpanel" aria-labelledby="fiat-tab">
              <div class="d-flex justify-content-between">
                <h6 class="text-white align-items-center">
                  <div class="text-xs text-warning"><i class="pi pi-users mb-1" style="font-size: 10px;"></i> Cluster Name</div>
                  <span v-if="Object.values(cluster).length === 0">No Active cluster</span>
                  <span v-else>
                    {{ cluster.name }}
                  </span>
                </h6>
                <div class="align-items-end"  id="step_4">
                  <div class="dropdown">
                    <button class="btn btn-primary dropdown-toggle bg-success" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="pi pi-cog" style="font-size: 10px;"></i> options
                    </button>
                    <ul class="dropdown-menu bg-green-700">
                      <template v-if="!loaders.fetchWalletPolicies">
                        <li @click="handleRequestClick('BALANCE_REQUEST', null)">
                          <a class="dropdown-item">
                            <i class="pi pi-lock" style="font-size: 10px;"></i> request balance
                          </a>
                        </li>
                        <li @click="handleRequestClick('TRANSACTION_REQUEST', 'business-transaction-request')">
                          <a class="dropdown-item">
                            <i class="pi pi-ban" style="font-size: 10px;"></i> request transaction
                          </a>
                        </li>
                        <li @click="handleMenuClick('member')">
                          <a class="dropdown-item">
                            <i class="pi pi-users" style="font-size: 10px;"></i> cluster members
                          </a>
                        </li>
                      </template>
                      <template v-else>
                        <li class="px-5">
                          <i>loading...</i>
                        </li>
                      </template>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <div>
                  <p v-if="!loaders.fetchBusinessById" @click="clickToCopy(getWalletNumber)">
                    Wallet No: <br/>{{ getWalletNumber }} <i class="pi pi-clone" style="font-size: 10px;"></i>
                  </p>
                  <p v-else class="small"><i>loading...</i></p>
                </div>
                <div>
                  <p class="mb-0 badge rounded bg-success text-xm">
                    <small v-if="!loaders.fetchBusinessById" @click="clickToCopy(business.account?.account_number || 'N/A')">
                      {{ business.account?.bank_name || 'Bank'}}: 
                      <span>
                        {{ business.account?.account_number || 'N/A'}}
                      </span>&nbsp;
                      <i class="pi pi-clone" style="font-size: 9px;"></i>
                    </small>
                    <small v-else><i>loading...</i></small>
                  </p>
                </div>
              </div>
              
              <div class="d-flex mt-2 justify-content-between">
                <div class="text-white">
                  <button class="btn btn-sm btn-warning shadow" data-bs-toggle="modal" data-bs-target="#payment-method" v-if="cluster && cluster.id">
                    <i class="pi pi-plus" style="font-size: 11px;"></i> Fund
                  </button>&nbsp;
                  <button v-if="!is('restricted')" 
                    class="btn btn-sm btn-warning shadow" 
                    @click="handleRequestClick('WITHDRAW_REQUEST', 'withdraw-method')"
                    :disabled="loaders.fetchWalletPolicies"
                  >
                    <i class="pi pi-sign-out" style="font-size: 11px;"></i> Request Money
                  </button>
                </div>

                <div class="d-flex justify-content-end">
                  <button 
                    class="btn btn-sm btn-warning shadow" 
                    data-bs-toggle="modal" 
                    data-bs-target="#cluster-chat-room"
                    @click="handleChatClick"
                    :disabled="!cluster || !cluster.id"
                  >
                    <i class="pi pi-comments" style="font-size: 11px;"></i> Chat
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container px-0" id="step_8">
      <ul class="box-service mt-3">
        <li 
          @click="handleMenuClick('cluster')"
        >
          <a class="stretched-link" href="#">
            <div 
              class="icon-box bg_color_1" 
              :class="{'selected': activeMenu === 'cluster'}"
            >
              <i class="pi pi-users"></i>
            </div>
            <span>Clusters</span>
          </a>
        </li>
        <li 
          @click="handleMenuClick('transaction')"
          v-if="Object.values(cluster).length > 0"
        >
          <a class="stretched-link" href="#">
            <div 
              class="icon-box bg_color_1"
              :class="{'selected': activeMenu === 'transaction'}"
            >
              <i class="pi pi-wave-pulse"></i>
            </div>
            <span>Transactions</span>
          </a>
        </li>
        <li
          @click="handleMenuClick('request')"
          v-if="Object.values(cluster).length > 0"
        >
          <a class="stretched-link" href="#">
            <div 
              class="icon-box bg_color_1"
              :class="{'selected': activeMenu === 'request'}"
            >
              <i class="pi pi-shield"></i>
            </div>
            <span>Request</span>
          </a>
        </li>
        <li 
          data-bs-toggle="modal" 
          data-bs-target="#join-cluster"
        >
          <a class="stretched-link" href="#">
            <div class="icon-box bg_color_1">
              <i class="pi pi-plus"></i>
            </div>
            <span>Join</span>
          </a>
        </li>
      </ul>
    </div>

    <modal-business id="join-cluster"></modal-business>
    <modal-chat id="cluster-chat-room" :cluster="cluster"></modal-chat>
    <modal-fund-account 
      id="payment-method" 
      :currency="getCurrency"
      :account="business.account || {}"
    ></modal-fund-account>
    <modal-withdrawal 
      id="withdraw-method" 
      :extract-charges="getExtraCharges"
      :native="true"
      @payload="requestBusinessRequest"
    ></modal-withdrawal>
    <modal-business-request 
      id="business-request" 
      :request="activeRequest || {}"
      @continue="handleContinueRequest"
    ></modal-business-request>
    <modal-transaction-filter
      title="Transaction Request" 
      id="business-transaction-request"
      @filter="requestBusinessRequest"
      @download="requestBusinessRequest"
    ></modal-transaction-filter>
    <modal-business-request-process 
      :title="`Creating ${requestTitle}`"
      id="request-completion-modal"
      :successful="isRequestSuccessful"
      @menu="$emit('menu', 'request')"
    >
    </modal-business-request-process>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Modal from '@/components/common/modal/Modal.vue'
import ProductPackage from '@/components/common/guard/ProductPackage.vue'
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import AppButton from '@/components/common/form/AppButton.vue';
import ModalTransactionFilter from '@/components/common/modal/ModalTransactionFilter.vue'
import bootstrapBundleMin from '@/assets/js/bootstrap.bundle.min';
import { printDiv, formatter } from '@/utils';
import moment from 'moment'
import { defineComponent } from 'vue'

import ModalBusinessRequest from '@/components/common/modal/ModalBusinessRequest.vue'
import ModalBusinessRequestProcess from '@/components/common/modal/ModalBusinessRequestProcess.vue'
import ModalFundAccount from '@/components/common/modal/ModalFundAccount.vue'
import ModalWithdrawal from '@/components/common/modal/ModalWithdrawal.vue'
import ModalBusiness from '@/components/common/modal/ModalBusiness.vue'
import ModalChat from '@/components/common/modal/ModalChat.vue'
import { walletRequest, strings } from '@/mixins'

export default defineComponent({
  name: 'Card',
  components: {
    Modal,
    AppButton,
    ProductPackage,

    ModalBusinessRequestProcess,
    ModalTransactionFilter,
    ModalBusinessRequest,
    ModalFundAccount,
    ModalWithdrawal,
    ModalBusiness,
    ModalChat
  },
  props: ['cluster'],
  mixins: [ walletRequest, strings ],
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      cluster: {
        type: { required },
        brand: { required },
        name: { required },
      }
    }
  },
  data() {
    return {
      chat: null,
      activeMenu: 'cluster',
      selectedFiatCurrency: {
        symbol: "NGN",
        balance: 0,
        avialable: 0,
        identity: ""
      },
    }
  },
  created() {
    this.moment = moment;
    this.printDiv = printDiv;
    this.formatter = formatter
  },
  computed: {
    ...mapGetters([
      "loaders",
      "auth",
      "business"
    ]),
    getWalletNumber() {
      if (!this.business.wallet || !this.selectedFiatCurrency.symbol) {
        return 'N/A'
      }
      return this.business.wallet[this.selectedFiatCurrency.symbol].identity || 'N/A'
    },
    getExtraCharges() {
      if (!this.activeRequest) return {}
      return {
        description: `Charges for ${this.activeRequest.type}`,
        amount: this.activeRequest.admin_fee
      }
    },
    getCurrency () {
      if (!this.business || !this.business.wallet) {
        return this.selectedFiatCurrency
      }
      return {
        ...this.business.wallet[this.selectedFiatCurrency.symbol],
        symbol: this.selectedFiatCurrency.symbol
      }
    }
  },
  methods: {
    handleMenuClick(menu) {
      this.activeMenu = menu
      this.$emit('menu', menu)
    },
    handleChatClick() {
      if (this.cluster.whatsapp_link) {
        const link = this.cluster.whatsapp_link
        window.open(link, '_blank')
        return
      }
      const chatRoomModal = document.getElementById('cluster-chat-room');
      const _chatRoomModal = bootstrapBundleMin.Modal.getInstance(chatRoomModal)
      _chatRoomModal.show();
    },
  }
})
</script>

<style scoped>
.bg-card-primary {
  --bs-bg-opacity: 1;
  background-color: var(--jw-text-success, var(--bs-bg-opacity)) !important;
}
.wallet-icon>svg>path, .wallet-icon>svg>g>path{
  fill: rgba(var(--bs-success-rgb));
}
.nav-item {
  padding: 2px 0px
}
.nav-item button {
  padding: 6px 0px
}
.colorful-tab .tab-content {
  background-color: rgba(0, 0, 0, 0) !important
}
.colorful-tab .btn-warning, .shepherd-button, .bg_color_1 {
  background-color: var(--warning-color) !important;
  border-color: var(--warning-color) !important;
  background: var(--warning-color) !important;
  columns: var(--primary-color) !important;
}
.input-group-text.select-input {
  padding: 0rem;
  border: 0px solid #ced4da;
}
.colorful-tab .btn.btn-warning {
  color: var(--primary-color) !important;
}
.colorful-tab-card .btn {
  font-size: 12px;
}
.vas a {
  text-decoration: none !important;
}
.vas .link {
  color: var(--jw-text-success) !important;
}
.btn-primary:hover {
    color: #fff;
    /* background-color: #0b5ed7; */
    background-color: rgba(var(--bs-success-rgb));
    border-color: #0a58ca;
}
.dropdown-menu.show {
    background-color: rgba(var(--bs-success-rgb));
    color: white;
}
.dropdown-menu.show li a{
    color: white;
}
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@import url('https://fonts.googleapis.com/css?family=Lato');
#pannel {
  background: url('https://www.shutterstock.com/image-vector/social-media-sketch-vector-seamless-600nw-1660950727.jpg');
  background-size: cover;
  background-position: center	center;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  font-family: 'Lato', sans-serif;
  margin: 0 0;
  height: 100%;
  overflow: hidden;
}
.pannel-box .controls {
  top: 0;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #fff;
  z-index: 1000;
  font-size: 10px;
}
.pannel-box .controls button {
  color: white;
  background-color: rgb(235, 12, 12);
  border: none;
  padding: 2px 10px;
  border-radius: 1px;
}
.pannel-box .controls span {
  padding: 2px 10px;
  font-size: 12px;
}
.pannel-box .chat-container {
  background-color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  padding: 15px;
  position: relative;
  height: 356px;
  max-width: 100%;
  width: 100%;
}
.pannel-box .chat-container .chat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  height: 100%;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.pannel-box .chat-container .chat::-webkit-scrollbar {
  display: none;
}
.pannel-box .chat-container .chat .message {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50px;
  box-shadow: 0px 15px 5px 0px rgba(0,0,0,0.5);
  position: relative;
  margin-bottom: 15px;
  font-size: 12px;
  max-width: 100%;
}
.pannel-box .chat-container .chat .message.sending {
  background-color: rgba(210, 210, 210, 0.9);
}
.pannel-box .chat-container .chat .message.error{
  background-color: rgba(255, 210, 210, 0.9);
}
.pannel-box .chat-container .chat .message.left{
  padding: 10px 20px 10px 50px;
}
.pannel-box .chat-container .chat .message.left small {
  margin-left: 0.5rem;
  color: #999;
  font-size: 8px;
}
.pannel-box .chat-container .chat .message.right {
  align-self: flex-end;
  padding: 10px 50px 10px 20px;
}
.pannel-box .chat-container .chat .message.right .logo {
  left: auto;
  right: 10px;
}
.pannel-box .chat-container .chat .message.right small {
  margin-right: 0.5rem;
  color: #999;
  font-size: 8px;
}
.pannel-box .chat-container .chat .message .logo {
  border-radius: 50%;
  box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.7);
  object-fit: cover;
  position: absolute;
  left: 10px;
  top: -10px;
  width: 32px;
  height: 32px;
}
.pannel-box .chat-container .chat .message p {
  margin: 0;
}
.pannel-box .chat-container .chat .message .message-file {
  cursor: pointer;
}
.pannel-box .form {
  position: relative;
  width: 100%;
}
.pannel-box .form .text_input {
  font-size: 16px;
  position: relative;
  left: 0;
  right: 0;
  padding: 10px 15px;
  padding-right: 40px;
  width: 100%;
  color: #666;
}
.pannel-box .form .message-control {
  position: absolute;
  top: 6px;
  right: 10px;
}
.pannel-box .form .message-control .attachment {
  display: none;
}
.pannel-box {
  width: 100%;
  position: relative;
}
</style>