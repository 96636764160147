<template>
  <page-template>
    <div class="page-content-wrapper py-2">
      
      <div class="card-section mt-2">
        <card @menu="handleMenuChange" :cluster="activeCluster"></card>
        
        <Transition name="bounce">
          <transaction-pin></transaction-pin>
        </Transition>

        <div class="notification-area mt-4">
          <div class="container">
            <TransitionGroup name="bounce">
              <cluster-list 
                v-if="menu==='cluster'" 
                :clusters="auth.clusters || []" :topic="`Clusters`"
                @cluster="handleClusterChange"
                @menu="handleMenuChange"
              ></cluster-list>
              <cluster-request 
                v-else-if="menu==='request'" 
                :requests="walletRequests" topic="Pending Cluster Member Request"
              />
              <cluster-member-list
                v-else-if="menu==='member'" 
                :members="business.business?.customers || []"
              ></cluster-member-list>
              <transaction-list 
                v-else-if="menu==='transaction'" 
                :transactions="transactions.data || []"
                :loading="loaders.fetchTransactions"
                :topic="`${activeCluster.name} transactions`"
              ></transaction-list>
            </TransitionGroup>
          </div>
        </div>
        <div class="my-6"></div>
      </div>
    </div>
  </page-template>
</template>

<script>
// @ is an alias to /src
import TransactionList from '@/components/pages/transaction/TransactionList.vue'
import ClusterMemberList from '@/components/pages/cluster/ClusterMemberList.vue'
import TransactionPin from '@/components/common/wallet/TransactionPin.vue'
import ClusterRequest from '@/components/pages/cluster/ClusterRequest.vue'
import ProductPackage from '@/components/common/guard/ProductPackage.vue'
import ClusterList from '@/components/pages/cluster/ClusterList.vue'
import PageTemplate from '@/components/templates/PageTemplate.vue'
import Card from '@/components/pages/cluster/Card.vue'
import { mapActions, mapGetters } from 'vuex'
import useVuelidate from '@vuelidate/core';

export default {
  name: 'ClusterPage',
  components: {
    PageTemplate,
    Card,
    ClusterList,
    TransactionPin,
    ProductPackage,
    TransactionList,
    ClusterRequest,
    ClusterMemberList
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {}
  },
  data() {
    const { banner } = this.appConfig || {};
    return {
      cardType: 'virual',
      menu: 'cluster',
      banner: banner || require("@/assets/images/common/22.jpeg"),
      activeCluster: {}
    }
  },
  computed: {
    ...mapGetters([
      "auth",
      "cluster",
      "appConfig",
      "transactions",
      "walletRequests",
      "loaders",
      "business"
    ])
  },
  async mounted() {
    const { clusters = []} = this.auth
    this.activeCluster = await Object.values(this.cluster).length > 0 ? this.cluster : clusters[0] || {}
    setTimeout(() => {
      if (this.activeCluster.id) {
        this.loadingClusterInfo(this.activeCluster);
      }
    }, 500)
  },
  methods: {
    ...mapActions([
      "getTransactions",
      "fetchBusinessById",
      "fetchWalletPolicies",
      "fetchWalletRequests"
    ]),
    loadingClusterInfo(cluster) {
      this.fetchBusinessById(cluster.id);
      this.fetchWalletPolicies({business_id: cluster.id})
      this.fetchWalletRequests({business_id: cluster.id})
      this.getTransactions({business_id: cluster.id})
    },
    handleClusterChange(cluster) {
      this.activeCluster = cluster
      this.loadingClusterInfo(cluster);
    },
    handleMenuChange(menu) {
      this.menu = menu
      if (menu === "transaction") {
        this.getTransactions({business_id: this.activeCluster.id})
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn.btn-warning {
  color: var(--primary-color) !important;
}
.cta-card {
  margin: 0px -16px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  /* border-bottom-right-radius: 80px;
  border-bottom-left-radius: 80px; */
  overflow: hidden;
  margin-bottom: 9px;
}
.card-section {
  position: relative;
  margin-top: -63px;
  /* z-index: 1; */
}

.cta-btn {
  font-size: 10px;
}


</style>
